import { trackEvent } from 'Config/TracingConfig'
import { getQueryParams, getAccessTokenDetails } from './device-helpers'

export const LOG_RV_INITIATING = 'RemoteControl-Web-Initiating'
export const LOG_RV_GET_ACCESS_TOKEN_FROM_SINAPS = 'RemoteControl-Web-Get-AccessToken-From-SINAPS'
export const LOG_RV_RECEIVED_ACCESS_TOKEN_FROM_SINAPS = 'RemoteControl-Web-Received-AccessToken-From-SINAPS'
export const LOG_RV_SESSION_INVALID = 'RemoteControl-Web-Session-Invalid'
export const LOG_RV_PAGE_REFRESHED = 'RemoteControl-Web-Page-Refreshed'
export const LOG_RV_TRIGGER_COMMAND_DEVCOMMAND_RVPSTART = 'RemoteControl-Web-Triggering-Command-DEVCOMMAND-RVPSTART'
export const LOG_RV_TRIGGER_COMMAND_DEVCOMMAND_RVPSTART_SUCCESS = 'RemoteControl-Web-Triggering-Command-DEVCOMMAND-RVPSTART-Success'
export const LOG_RV_COMMAND_DEVCOMMAND_RVPSTART_FAILED = 'RemoteControl-Web-Command-DEVCOMMAND-RVPSTART-Failed'
export const LOG_RV_COMMAND_DEVCOMMAND_RVPSTART_DEVICE_OFFLINE = 'RemoteControl-Web-Device-Offline-Command-Failed'
export const LOG_RV_INITIATING_SOCKET_CONNECTION = 'RemoteControl-Web-Initiating-Socket-Connection'
export const LOG_RV_SOCKET_CONNECTION_SUCCESS = 'RemoteControl-Web-Socket-Connection-Success'
export const LOG_RV_CHECK_DEVICE_SOCKET_CONNECTION = 'RemoteControl-Web-Check-Device-Socket-Connection'
export const LOG_RV_CHECK_DEVICE_SOCKET_CONNECTION_FAILED = 'RemoteControl-Web-Check-Device-Socket-Connection-Failed'
export const LOG_RV_INITIATING_WEBRTC_CONNECTION = 'RemoteControl-Web-Initiating-WEBRTC-Connection'
export const LOG_RV_WEBRTC_DATACHANNEL_OPEN = 'RemoteControl-Web-WEBRTC-Datachannel-Open'
export const LOG_RV_WEBRTC_DATACHANNEL_CLOSED = 'RemoteControl-Web-WEBRTC-Datachannel-Closed'
export const LOG_RV_WEBRTC_DATACHANNEL_MESSAGE_60SECONDS_TIMEOUT = 'RemoteControl-Web-WEBRTC-Datachannel-60Seconds-Message-Not-Received'
export const LOG_RV_60SECONDS_TIMEOUT = 'RemoteControl-Web-60seconds-Timeout'
export const LOG_RV_WINDOW_CLOSED_OR_REFRESHED = 'RemoteControl-Window-Closed-Or-Refreshed'
export const LOG_RV_STARTED = 'RemoteControl-Web-Started'
export const LOG_RV_STOPPED = 'RemoteControl-Web-Stopped'
export const LOG_RV_FAILED_TO_START = 'RemoteControl-Web-FailedToStart'
export const LOG_RV_SESSION_EXPIRED = 'RemoteControl-Web-SessionExpired'
export const LOG_RV_UNAUTHORIZED_ACCESS = 'RemoteControl-Web-UnauthorizedAccess'
export const LOG_RV_UNABLE_TO_REACH_DEVICE = 'RemoteControl-Web-Unable-To-Reach-Device'
export const LOG_RV_DEVICE_USER_DECLINED = 'RemoteControl-Web-Device-User-Declined'
export const LOG_RV_DEVICE_AUTODISMISS_REMOTE_CONTROL_EVENT = 'RemoteControl-Web-Device-AutoDismiss-Remote-Control'
export const LOG_RV_CHECKING_FILE_EXPLORER_AVAILABILITY = 'RemoteControl-Web-Checking-FileExplorer-Availability'
export const LOG_RV_FILE_EXPLORER_IS_AVAILABLE = 'RemoteControl-Web-FileExplorer-Is-Available'
export const LOG_RV_FILE_EXPLORER_IS_NOT_AVAILABLE = 'RemoteControl-Web-FileExplorer-Is-Not-Available'
export const LOG_RV_SS_WEBSOCKET_UNAUTHORIZED_ACCESS = 'RemoteControl-Web-SS-Websocket-unauthorized-Access'
export const LOG_RV_SS_ANOTHER_REMOTE_ACCESS_IS_ACTIVE = 'RemoteControl-Web-SS-Another-RemoteAccess-Is-Active'
export const LOG_FE_FETCH_FILEDATA_STARTED = 'RemoteControl-Web-FileExplorer-FetchFileData-Started'
export const LOG_FE_FETCH_FILEDATA_COMPLETED = 'RemoteControl-Web-FileExplorer-FetchFileData-Completed'
export const LOG_FE_FETCH_FILEDATA_FAILED = 'RemoteControl-Web-FileExplorer-FetchFileData-Failed'
export const LOG_FE_FILEUPLOAD_STARTED = 'RemoteControl-Web-FileExplorer-UploadStarted'
export const LOG_FE_FILEUPLOAD_COMPLETED = 'RemoteControl-Web-FileExplorer-UploadCompleted'
export const LOG_FE_FILEUPLOAD_CANCELLED = 'RemoteControl-Web-FileExplorer-UploadCancelled'
export const LOG_FE_FILEDELETE_STARTED = 'RemoteControl-Web-FileExplorer-DeleteStarted'
export const LOG_FE_FILEDELETE_COMPLETED = 'RemoteControl-Web-FileExplorer-DeleteCompleted'
export const LOG_FE_FILEDELETE_FAILED = 'RemoteControl-Web-FileExplorer-DeleteFailed'
export const LOG_FE_FILEDOWNLOAD_STARTED = 'RemoteControl-Web-FileExplorer-DownloadStarted'
export const LOG_FE_FILEDOWNLOAD_COMPLETED = 'RemoteControl-Web-FileExplorer-DownloadCompleted'
export const LOG_FE_FILEDOWNLOAD_FAILED = 'RemoteControl-Web-FileExplorer-DownloadFailed'

export function logEvent (message, props) {
  const deviceId = getQueryParams('deviceId', window.location.href) || 'NA'
  const { email = 'NA' } = getAccessTokenDetails() || {}
  trackEvent({ name: message, properties: { deviceId, email, ...props } })
}
