import app from './app'

app.bits = function(){
	"use strict";
	
	function isSet(val, mask){
		if((val & mask) != 0){
			return true;
		}
		return false;
	}
	
	function set(val, mask){
		return val |= mask;
	}
	
	function clear(val, mask){
		return val &= ~mask;
	}
	
	function toggle(val, mask){
		return val ^= mask;
	}

	// public API
	return {set: set,
			isSet: isSet,
			clear: clear,
			toggle: toggle};
}();
