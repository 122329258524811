import styled from 'styled-components/macro'

export const FileExplorerContainer = styled.div`
    height: 100%;
    display: flex;
    margin-top: 1.7rem;
    background: #F7F7F7;
    box-shadow: 0 0 5px;
`
export const StyledErrorContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: center;
`
export const StyledItemContainer = styled.div`
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    .h-icon.common{
        color: #E0E0E0 !important;
        font-weight:600 !important;
    }
`
export const OnboardTitle = styled.div`
    font-size: 1.5em;
    font-weight: 500;
    color: #404040;
    margin: 1rem;
`

export const OnboardSubtitle = styled.div`
    font-size: 1.25em;
    font-weight: 500;
    color: #606060;
    text-align: center;
`
