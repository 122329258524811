/* eslint-disable complexity */
export function findFileType (type, format) {
  if (type === 'folder') return 'Folder'
  else if (type === 'file') {
    switch (format) {
      case 'apk': return 'APK File'
      case 'zip': return 'ZIP File'
      case 'cfa': return 'CFA File'
      case 'log': return 'Log File'
      case 'pdf': return 'PDF File'
      case 'jpg': return 'JPG File'
      case 'png': return 'PNG File'
      case 'mp3': return 'MP3 File'
      case 'mp4': return 'MP4 File'
      case 'txt': return 'TXT File'
      case 'docx': return 'Word Document'
      case 'doc': return 'Word Document'
      case 'ppt': return 'PowerPoint Presentation'
      case 'xlsx': return 'Excel File'
      case 'xls': return 'Excel File'
      case 'csv': return 'CSV File'
      case 'msg': return 'Outlook Item'
      case 'exe': return 'Application'
      case 'msi': return 'Installer Package'
      case 'ini': return 'Configuration Settings'
      case 'json': return 'JSON File'
      case 'js': return 'Javascript File'
      case 'html': return 'HTML Document'
      case 'css': return 'Cascading Style Sheet Document'
      case 'java': return 'Java File'
      case 'xml': return 'XML Document'
      default : return 'File'
    }
  }
  return 'File'
}
