import JSZip from 'jszip'

import { FILE_CHUNK_SIZE } from 'Utils/constants'
import {
  sendStartUploadCommand,
  checkBufferedLowBrowserSupport,
  setBufferLowThreshold,
  isBufferedAmountAboveThreshold,
  registerBufferedLowEventListener,
  unRegisterBufferedLowEventListener,
  sendUploadFileChunk
} from 'Services/webrtc-connection'
import {
  logEvent,
  LOG_FE_FILEUPLOAD_COMPLETED,
  LOG_FE_FILEUPLOAD_STARTED,
  LOG_FE_FILEUPLOAD_CANCELLED
} from './log-helper'

let continueUploading = true
export function uploadFile (file, path, updateProgressPercentage, endOfUploading) {
  logEvent(LOG_FE_FILEUPLOAD_STARTED, { fileName: file.name, fileSize: file.size, path })
  sendStartUploadCommand(path)
  updateProgressPercentage(0)

  const chunkSize = FILE_CHUNK_SIZE
  let offset = 0
  let bufferFullThreshold = 5 * chunkSize
  let usePolling = true
  let chunkFile = ''

  if (checkBufferedLowBrowserSupport()) {
    usePolling = false
    bufferFullThreshold = chunkSize / 2
    setBufferLowThreshold(bufferFullThreshold)
  }

  const encryptAndZipFileReader = new FileReader()
  encryptAndZipFileReader.readAsArrayBuffer(file)
  encryptAndZipFileReader.addEventListener('load', e => {
    const receiveBuffer = []
    receiveBuffer.push(new Uint8Array(e.target.result))
    const received = new Blob(receiveBuffer)

    const zip = new JSZip()
    zip.file(file.name, received)
    zip.generateAsync({ type: 'blob', compression: 'DEFLATE' })
      .then((zipFile) => {
        const fileReader = new FileReader()
        const slice = zipFile.slice(offset, 0 + chunkSize)
        let sendDataTimeout = null
        fileReader.readAsArrayBuffer(slice)
        fileReader.addEventListener('load', loadFileReader)

        function loadFileReader (e) {
          chunkFile = e.target.result
          sendAllData()
        }

        const listener = () => {
          unRegisterBufferedLowEventListener(listener)
          sendAllData()
        }

        const sendAllData = () => {
          if (!getContinueUploading()) {
            logEvent(LOG_FE_FILEUPLOAD_CANCELLED, { fileName: file.name })
            cleanUp()
            return
          }
          if (isBufferedAmountAboveThreshold(bufferFullThreshold)) {
            if (usePolling) {
              sendDataTimeout = setTimeout(sendAllData, 250)
            } else {
              registerBufferedLowEventListener(listener)
            }
            return
          }

          const base64String = btoa(String.fromCharCode.apply(null, new Uint8Array(chunkFile)))
          sendUploadFileChunk(zipFile.size, base64String)
          offset += chunkFile.byteLength
          updateProgressPercentage(calculateUploadPercent(offset, { size: zipFile.size, name: file.name, path }, endOfUploading, cleanUp))
          if (offset < zipFile.size) {
            readSlice(offset)
          }
        }

        const readSlice = o => {
          const slice = zipFile.slice(offset, o + chunkSize)
          fileReader.readAsArrayBuffer(slice)
        }

        function cleanUp () {
          fileReader.removeEventListener('load', loadFileReader)
          unRegisterBufferedLowEventListener(listener)
          clearTimeout(sendDataTimeout)
          setContinueUploading(true)
        }
      })
      .catch(e => {})
  })
}

function calculateUploadPercent (offset, file, endOfUploading, cleanUp) {
  if (offset === file.size) {
    logEvent(LOG_FE_FILEUPLOAD_COMPLETED, { fileName: file.name, fileSize: file.size, path: file.path })
    endOfUploading(file.name)
    cleanUp()
    return 100
  }
  return Math.round((offset / file.size) * 100)
}

export function setContinueUploading (status) {
  continueUploading = status
}

export function getContinueUploading () {
  return continueUploading
}
/* function encryptAndZipFile (file) {
  const encryptAndZipFileReader = new FileReader()
  encryptAndZipFileReader.readAsArrayBuffer(file)
  encryptAndZipFileReader.addEventListener('load', e => {
    const sha1 = cryptojs.algo.SHA1.create()
    const wordBuffer = cryptojs.lib.WordArray.create(e.target.result)
    sha1.update(wordBuffer)
    const encrypted = sha1.finalize().toString()

    const receiveBuffer = []
    receiveBuffer.push(new Uint8Array(e.target.result))
    const received = new Blob(receiveBuffer)

    const zip = new JSZip()
    zip.file(file.name, received)
    return zip.generateAsync({ type: 'blob', compression: 'DEFLATE' })
  })
} */
