import io from 'socket.io-client'
import { put, call, takeLatest, all, select } from 'redux-saga/effects'

import { useAppDispatch } from 'Store'

import config from 'Config/AppConfig'
import { RemoteScreenApi } from 'Services'
import { RemoteScreenTypes, RemoteScreenActions } from './actions'

function * triggerCommand ({ deviceId, cmdStr }) {
  // yield put(RemoteScreenActions.updateDeviceId('1234567'))
  const response = yield call(RemoteScreenApi.triggerCommand, { deviceId, cmdStr })
  if (!response.ok) {
   
  }
}

function * testSocketGen ({ deviceId }) {
  // setTimeout(() => resolve('done!'), 5000)
  yield 1
  yield 2
  yield 3
}

function testSocket ({ deviceId }) {
  return new Promise((resolve, reject) => {
    // setTimeout(() => resolve('done!'), 5000)
    const socketRTC = io(config.signallingServer.socketURL, {
      transports: ['websocket'],
      reconnection: false,
      query: {
        token: global.sessionStorage.getItem('accessToken'),
        deviceId,
        type: 'browser'
      }
    })

    socketRTC.on('message', socketRTCMessage)

    socketRTC.on('id', (id) => {
      resolve(id)
    })
  })
  // const result = await promise
  // console.log('result >', result)
}

function startSocketRTCConnection ({ deviceId }) {
  console.log('In startSocketRTCConnection > ', deviceId)

  const socketRTC = io(config.signallingServer.socketURL, {
    transports: ['websocket'],
    reconnection: false,
    query: {
      token: global.sessionStorage.getItem('accessToken'),
      deviceId,
      type: 'browser'
    }
  })

  socketRTC.on('message', socketRTCMessage)

  socketRTC.on('id', async () => {
    await 'helloworld'
  })

  /* socketRTC.on('id', (id) => {
   // browserPeer = id
    console.log('Connected To Server With ID -> ' + id)

    yield put(RemoteScreenActions.updateDeviceId('123456'))

    getDeviceConnectionStatus(deviceId).then(response => {
      if (response) {
        const { isActive, clientId } = response
        if (isActive) {
          startPeerConnection({ clientId, deviceId })
          peerConnectionStarted = true
        } else {
          waitingTimeout = setTimeout(() => {
            if (!peerConnectionStarted) {
              handelErrorCondition()
            }
          }, 60000)
        }
      } else {
        handelErrorCondition()
      }
    }).catch(error => console.log(error))
  }) */
  /* socketRTC.on('deviceConnected', (deviceDetails) => {
    if (deviceId === deviceDetails.deviceId) {
      console.log(`Received Socket message as deviceConnected from ${deviceDetails.deviceId}`)
      clearTimeout(waitingTimeout)
      startPeerConnection(deviceDetails)
      peerConnectionStarted = true
    }
  })

  socketRTC.on('deviceDisconnected', (deviceDetails) => {
    if (deviceId === deviceDetails.deviceId) {
      console.log(`Received Socket message as deviceDisconnected from ${deviceDetails.deviceId}`)
      handelErrorCondition()
    }
  })
  socketRTC.on('dataChannelRestarted', (deviceDetails) => {
    if (deviceId === deviceDetails.deviceId) {
      console.log(`Received Socket message as dataChannelRestarted from ${deviceDetails.deviceId}`)
      app.tcpServer.restartImageProcessing()
    }
  })

  socketRTC.on('error', (error) => {
    console.log('Received Socket error event > ', error)
    if (error === '401') {
      triggerRVStop = false
      $('#user-msg').html('Unauthorized access, please login and try again.')
      $('#loading-icon').css({ display: 'none' })
    } else if (error === '101') {
      triggerRVStop = false
      $('#user-msg').html('Another remote access is currently active on this device. Please try later.')
      $('#loading-icon').css({ display: 'none' })
    }
    $('.footer').hide()
  })

  socketRTC.on('deviceError', (errorDetails) => {
    if (deviceId === errorDetails.deviceId) {
      console.log(`Received Socket message as deviceError from ${errorDetails.deviceId}`, errorDetails)
      handelErrorCondition()
    }
  })

  socketRTC.on('connect_error', () => {
    console.log('Connection Failed')
    handelErrorCondition()
  }) */
}

function socketConnected (id) {
  console.log('socketConnected > ', id)
  socketConnectedGen().next()
}

function * socketConnectedGen (id) {
  yield put(RemoteScreenActions.updateDeviceId('123456789'))
}

function socketRTCMessage () {
  console.log('In > socketRTCMessage')
}

export function * remoteScreenSagas () {
  yield all([
    takeLatest(RemoteScreenTypes.TRIGGER_COMMAND, triggerCommand)
  ])
}
