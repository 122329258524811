import React from 'react'

import { Divider, Progress } from '@scuf/common'
import DisplayName from '../FolderTree/display-name'
import {
  StyledProgressContainer,
  StyledDot,
  StyledFailedWrapper,
  StyledIconCheck,
  StyledIconClose,
  StyledIconWarning,
  StyledProgressBarWrapper,
  StyledProgressBoldLabel,
  StyledProgressCol1,
  StyledProgressCol2,
  StyledProgressNamelabel,
  StyledFailedText,
  StyledPercent
} from './progress-bar.styles'

const ProgressBar = ({
  progressLabel,
  failedLabel,
  fileName,
  percentage,
  inProgress,
  failedStatus,
  handleCloseProgressBar
}) => {
  return (
    <StyledProgressContainer failedStatus={failedStatus}>
      <StyledProgressCol1 percent={percentage} failedStatus={failedStatus}>
        <StyledProgressBoldLabel>{progressLabel}</StyledProgressBoldLabel>
        <Divider direction='vertical' />
        {!inProgress
          ? <>
            {!failedStatus
              ? <StyledIconCheck name='check' size='small' root='common' />
              : <StyledIconWarning name='badge-important' size='small' root='common' />}
            <StyledIconClose name='close' size='small' root='common' onClick={handleCloseProgressBar} />
            </>
          : null}

        <StyledProgressNamelabel><DisplayName name={fileName} /></StyledProgressNamelabel>
        {failedStatus
          ? <StyledFailedWrapper>
            <StyledDot />
            <StyledFailedText>{failedLabel}</StyledFailedText>
            </StyledFailedWrapper>
          : null}
      </StyledProgressCol1>
      {inProgress && percentage >= 0
        ? <StyledProgressCol2>
          <StyledProgressBoldLabel>{percentage >= 0 ? `${percentage}%` : null}</StyledProgressBoldLabel>
          <StyledProgressBarWrapper>
            {percentage > 0
              ? <StyledPercent style={{ width: `${percentage}%` }} />
              : <Progress size='small' />}
          </StyledProgressBarWrapper>
          </StyledProgressCol2>
        : null}
    </StyledProgressContainer>
  )
}

export default React.memo(ProgressBar)
