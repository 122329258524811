import app from './app'

export default app.storage = function(){
	"use strict";

	const FILE_SYSTEM = true;
	const FILE = "storage.db";
	
	// private methods
	function getPath(){
		//var dir = process.cwd().replace(/\\/g, "/"),
			//path = dir + "/" + FILE;
			
		var path = "c:/honeywell/" + FILE; // debug only!
			
		return path;
	}
	
	function readDatabase(){
		/*var path = getPath();
		
		if(app.fs.existsSync(path)){
			var data = app.fs.readFileSync(path, "utf8"),
				db = JSON.parse(data);
				
			return db;
		}else{
			return [];
		}
		*/
	}

	function writeDatabase(db){
		var path = getPath(),
			str = JSON.stringify(db);
			
		app.fs.writeFileSync(path, str);
	}
	
	function addDatabaseItem(db, key, data){
		db.push({
			key: key,
			data: data
		});
	}
	
	function updateDatabaseItem(db, key, data){
		for(var ii = 0; ii < db.length; ii++){
			if(key === db[ii].key){
				db[ii].data = data;
				break;
			}
		}
	}
	
	function getDatabaseItem(db, key){
		if(db && db.length){
			for(var ii = 0; ii < db.length; ii++){
				if(key === db[ii].key){
					return db[ii].data;
				}
			}
		}
		
		return null;
	}

	// public methods
	function getItem(key){
		var str, 
			db = readDatabase();
		
		if(FILE_SYSTEM){
			str = getDatabaseItem(db, key);
		}else{
			str = localStorage.getItem(key);
		}

		if(str && str.length){
			return JSON.parse(str);
		}
		
		return null;
	}
	
	function setItem(key, data){
		data = JSON.stringify(data);
		
		if(FILE_SYSTEM){
			var db = readDatabase(),
				str = getDatabaseItem(db, key);
				
			if(str && str.length){
				updateDatabaseItem(db, key, data);
			}else{
				addDatabaseItem(db, key, data);
			}
			
			writeDatabase(db)
			
		}else{
			localStorage.setItem(key, data);
		}
	}
	
	function removeItem(key){
		if(FILE_SYSTEM){
			var path = getPath();
			
			if(app.fs && app.fs.existsSync(path)){
				app.fs.unlinkSync(path);
			}
		}else{
			localStorage.removeItem(key);
		}
	}
	
	// public API
	return {getItem: getItem,
			setItem: setItem,
			removeItem: removeItem};
}();
