import app from './app'

export default app.server = (function () {
  'use strict'

  const WEB_SOCKETS = false

  // private methods

  // public methods
  function stop () {
    if (WEB_SOCKETS) {
      app.wsServer.stop()
    } else {
      app.tcpServer.stop()
    }
  }

  function start (port, type) {
    if (WEB_SOCKETS) {
      app.wsServer.start(port)
    } else {
      app.tcpServer.start(port)
    }
  }

  function write (obj, callback) {
    if (obj && callback) {
      if (WEB_SOCKETS) {
        app.wsServer.write(obj, callback)
      } else {
        app.tcpServer.write(obj, callback)
      }
    }
  }

  // public API
  return {
    stop: stop,
    start: start,
    write: write
  }
}())
