import { Icon, Popup, VerticalMenu } from '@scuf/common'
import styled from 'styled-components/macro'

export const FolderTreeWrapper = styled.div`
 width: 25%;
 display:flex;
 background: #FFF;
`
export const FolderTreeContainer = styled.div`
  border: 1px solid #d8d8d8;
  width: 100%;
  max-width: 100%;
  overflow-y: scroll;
  margin: 1.5rem;
  background: #FFF;
  line-height: 1.5;

  & .popup-pointer{
    margin-left: auto;
    margin-right: 1rem;
  } 
`

export const StyledFile = styled.div`  
  display: flex;
  align-items: center;
  padding: 1rem 0 1rem 1rem;
  & svg {
    color: #303030;
    margin-right: .5rem;
    flex-shrink: 0;
  }  
`
export const StyledFolder = styled.div`
  
  padding-left: 1rem;
`
export const FolderWrapper = styled.div`
  padding-left: 0px;
`

export const FolderLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  color: #303030;

  & svg {
    color: #303030;
    margin-right: .5rem;
    flex-shrink: 0;
  } 
`

export const FolderNameWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow-x: hidden;
  cursor: pointer;
`

export const Collapsible = styled.div`
  height: ${p => (p.isOpen ? 'auto' : '0')};
  overflow: ${p => (p.isOpen ? 'unset' : 'hidden')};
  padding-left: 20px;
  background-color: #f7f7f7;
`
export const FolderName = styled.div`
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`

export const FileName = styled.div`
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
`
export const StyledPopup = styled(Popup)`
  width: 200px !important;  
`

export const StyledVerticalMenu = styled(VerticalMenu)`
  width: 200px !important  
`
export const StyledIconEllipsis = styled(Icon)`
  color: #1274B7;
  margin-top: -3px;
`
