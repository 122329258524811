import AppServer from 'Legacy/UpdatedApp/server'

// This is used from legacy code, it requires refactoring.

const keyPressCodes = [34, 43, 44, 45, 47, 58, 59, 60, 61, 62, 63, 91, 92, 93, 95, 96, 123, 124, 125, 126] // And 48-57 for 0-9 numbers, shiftKey should be false.
const keyDownCodes = [8, 9, 13, 17, 18, 20, 27, 32, 33, 34, 35, 36, 37, 38, 39, 40, 46, 106, 144, 190, 222, 110] // And 65-90 for a-z, A-Z alphabets And 48-57 for special characters, shiftKey should be true.

export function registerKeyboardEvents () {
  const canvasBox = document.querySelector('#canvas-box')

  canvasBox.addEventListener('keypress', (event) => {
    if (event.ctrlKey) return
    const code = event.which || event.keyCode
    const { shiftKey } = event

    if (keyPressCodes.includes(code) || (!shiftKey && code >= 48 && code <= 57)) {
      const obj = { code, keyboard: true }
      AppServer.write(obj, () => { })
    }
  })

  canvasBox.addEventListener('keydown', (event) => {
    if (event.ctrlKey) return
    let code = event.which || event.keyCode
    const { shiftKey } = event

    if (keyDownCodes.includes(code) || (code >= 65 && code <= 90) || (shiftKey && code >= 48 && code <= 57)) {
      if (shiftKey && code === 222) return
      if (shiftKey && code === 190) return
      if (shiftKey) {
        const shiftObj = { code: 16, keyboard: true }
        AppServer.write(shiftObj, () => { })
      }

      // PAGE UP(33) - 136, PAGE DOWN(34) - 137, INSERT(45) - 138, DELETE(46) - 127, NUM PAD *(106) - 56, MINUS(189) - 45, PERIOD(190) - 46 key codes are mapped respectively.
      if (code === 33) {
        code = 136
      } else if (code === 34) {
        code = 137
      } else if (code === 45) {
        code = 138
      } else if (code === 46) {
        code = 127
      } else if (code === 106) {
        code = 42
      } else if (code === 189 || code === 109) {
        code = 45
      } else if (code === 190 || code === 110) {
        code = 46
      } 
      const obj = { code, keyboard: true }
      AppServer.write(obj, () => { })
    }
  })

  document.addEventListener('paste', (event) => {
    // event.preventDefault()
    if (event.currentTarget.activeElement.id !== 'canvas-box') return
    const paste = (event.clipboardData || window.clipboardData).getData('text')
    if (paste.length) {
      const obj = { input: paste }
      AppServer.write(obj, () => { })
    }
  })
}
