import React from 'react'

import {
  Wrapper,
  LoaderIcon,
  Message
} from './loader.styles'

const loader = ({
  message
}) => {
  return (
    <Wrapper>
      <LoaderIcon />
      <Message>
        {message}
      </Message>
    </Wrapper>
  )
}

export default loader
