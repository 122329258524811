import { createStructuredSelector } from 'reselect'

const dataChannelStatus = ({ remoteScreen }) => remoteScreen.getIn(['dataChannelStatus'], false)
const loading = ({ fileExplorer }) => fileExplorer.getIn(['loading'], false)
const isFileExplorerAvailable = ({ fileExplorer }) => fileExplorer.getIn(['isFileExplorerAvailable'], false)
const agentVersion = ({ fileExplorer }) => fileExplorer.getIn(['agentVersion'], 'Not Available')

export default createStructuredSelector({
  loading,
  dataChannelStatus,
  isFileExplorerAvailable,
  agentVersion
})
