import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

// import persistConfig from 'Config/PersistorConfig'
import config from '../Config/AppConfig'
import Reactotron from '../Config/ReactotronConfig'
import { rootReducer } from './root-reducer'
import sagas from '../Sagas'
import { setToken } from '../Services/ApiSauceInstance'
import { useDispatch } from 'react-redux'

function createStore (debug) {
  if (debug) {
    Reactotron.connect()
  }

  const sagaMiddleware = debug
    ? createSagaMiddleware({ sagaMonitor: Reactotron.createSagaMonitor() })
    : createSagaMiddleware({})

  return {
    store: configureStore({
      reducer: rootReducer,
      middleware: [
        sagaMiddleware,
        ...getDefaultMiddleware({
          immutableCheck: debug
        })
      ],
      enhancers: debug ? [Reactotron.createEnhancer()] : []
    }),
    sagaMiddleware
  }
}

const { store, sagaMiddleware } = createStore(config.debug)

/* if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./root-reducer', () => {
    const newRootReducer = require('./root-reducer').default
    store.replaceReducer(persistReducer(persistConfig, newRootReducer))
  })
}
 */
// mount it on the Store
// store.subscribe(() => {
//   const state = store.getState()
//   // const m = moment()
//   console.log('Hello Global State')
// })

sagaMiddleware.run(sagas)

export const useAppDispatch = () => useDispatch()
export { store }
