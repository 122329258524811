import $ from 'jquery'
import app from './app'

export default app.dialog = (function () {
  const DIALOG_BACKGROUND = 'white'
  const DIALOG_TITLEBAR_COLOR = 'white'
  const DIALOG_TITLEBAR_BACKGROUND = 'red'
  const DIALOG_TITLEBAR_BORDER_COLOR = 'red'
  const REMOTE_INPUT_PLACEHOLDER = 'Type or copy-n-paste here...'

  const DIALOG_CHECKBOX_CHECKED_CLASS = 'ui-state-active'
  const DIALOG_CHECKBOX_UNCHECKED_CLASS = 'ui-state-default'
  const DEVICE_BACKGROUND_COLOR = 'black'

  let $receiveInput = null

  function closeDialog () {
    $('#dialog-error').dialog('close')
  }

  // private methods
  function readVersionXML () {
    const file = process.cwd().replace(/\\/g, '/') + '/version.xml'

    if (app.fs.existsSync(file)) {
      const xml = app.fs.readFileSync(file, 'utf8')
      const obj = app.xml.xml2json(xml)

      return { version: obj.RC_SERVER.version, build: obj.RC_SERVER.build }
    }

    return null
  }

  function getAppVersion () {
    const obj = readVersionXML()
    return obj.version
  }

  function startReceivingKeyboardInput ($dialogForm) {
    $receiveInput = $dialogForm
  }

  function stopReceivingKeyboardInput () {
    $receiveInput = null
  }

  function commandButtonList () {
    const buttons = []
    const $buttons = $('.footer').find('button')

    $buttons.each(function () {
      const $this = $(this)

      buttons.push({
        id: $this.attr('id'),
        name: $this.text(),
        enabled: $this.is(':visible')
      })
    })

    return buttons
  }

  function commandButtonHTML (buttons, id) {
    let html =	'<div id="' + id + '" title="Preferences: Command Button Visibility / Order">' +
						'<fieldset>' +
							'<legend>Options</legend>' +
							'<button class="ui-state-default" id="select-all-button">Select All</button>' +
							'<button class="ui-state-default" id="unselect-all-button">Unselect All</button>' +
							'<button class="ui-state-default" id="sort-ascending-button">Sort Ascending</button>' +
							'<button class="ui-state-default" id="sort-descending-button">Sort Descending</button>' +
							'<button class="ui-state-default" id="default-layout-button">Default Layout</button>' +
						'</fieldset>' +
						'<ul class="list">'

    buttons.forEach(($this) => {
      const id = $this.id.replace('-checkbox', '')
      const { name } = $this
      const enabled = $this.enabled ? 1 : 0

      html +=		'<li class="ui-state-default"><span class="ui-icon ui-icon-arrowthick-2-n-s"></span>' +
							'<input type="checkbox" state="' + enabled + '" name="' + id + '-checkbox" id="' + id + '-checkbox">' +
							'<label for="' + id + '-checkbox">' + name + '</label>' +
						'</li>'
    })

    html +=			'</ul>' +
					'</div>'

    return html
  }

  function manageButtons ($dialogForm) {
    const $selectAllButton = $('#select-all-button')
    const $unselectAllButton = $('#unselect-all-button')
    const $sortAscendingButton = $('#sort-ascending-button')
    const $sordDescendingButton = $('#sort-descending-button')
    const $defaultLayoutButton = $('#default-layout-button')

    $selectAllButton.click(() => {
      const $list = $dialogForm.find('.list')
      const $li = $list.find('li')
      const $input = $list.find('input')

      $li.addClass(DIALOG_CHECKBOX_CHECKED_CLASS)
      $li.removeClass(DIALOG_CHECKBOX_UNCHECKED_CLASS)

      $input.each(function () {
        this.checked = true
      })
    })

    $unselectAllButton.click(() => {
      const $list = $dialogForm.find('.list')
      const $li = $list.find('li')
      const $input = $list.find('input')

      $li.removeClass(DIALOG_CHECKBOX_CHECKED_CLASS)
      $li.addClass(DIALOG_CHECKBOX_UNCHECKED_CLASS)

      $input.each(function () {
        this.checked = false
      })
    })

    $sortAscendingButton.click(() => {
      const $list = $dialogForm.find('.list')
      const $li = $list.find('li')
      const $label = $li.find('label')

      $label.sort((label1, label2) => {
        const name1 = label1.innerText.toLowerCase()
        const name2 = label2.innerText.toLowerCase()

        if (name1 < name2) {
          return -1
        }
        if (name1 > name2) {
          return 1
        }

        return 0
      })

      // reorder
      reorderCheckboxes($label, $li, $list)
    })

    $sordDescendingButton.click(() => {
      const $list = $dialogForm.find('.list')
      const $li = $list.find('li')
      const $label = $li.find('label')

      $label.sort((label1, label2) => {
        const name1 = label1.innerText
        const name2 = label2.innerText

        if (name1 < name2) {
          return 1
        }
        if (name1 > name2) {
          return -1
        }

        return 0
      })

      // reorder
      reorderCheckboxes($label, $li, $list)
    })

    $defaultLayoutButton.click(() => {
      const buttons = app.commands.defaultButtonLayout()
      const $dialog = $dialogForm.closest('div[role=dialog]')
      const $closeButton = $dialog.find('.ui-dialog-titlebar-close')

      $closeButton.click()

      commands(buttons)
    })
  }

  function reorderCheckboxes ($label, $sibling, $list) {
    let $prev = null
    let sibling = null

    $label.each(function () {
      for (let ii = 0; ii < $sibling.length; ii++) {
        if (this.innerText === $sibling[ii].innerText) {
          sibling = $sibling[ii]
          break
        }
      }

      if ($prev) {
        // move after previous
        $(sibling).insertAfter($prev)
      } else {
        // move to top
        $(sibling).prependTo($list)
      }

      $prev = $(sibling)
    })
  }

  function manageCommandButtonList ($dialogForm) {
    const $list = $dialogForm.find('.list')
    const $input = $list.find('input')
    const $li = $list.find('li')

    $list.sortable({
      placeholder: 'ui-state-highlight'
    })

    $list.disableSelection()

    $list.css({
      clear: 'both',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      width: '100%'
    })

    $li.css({
      margin: 0,
      padding: 5
    })

    $input.each(function () {
      const $this = $(this)
      const enabled = parseInt($this.attr('state'))

      if (enabled) {
        this.checked = true
        $this.closest('li').addClass(DIALOG_CHECKBOX_CHECKED_CLASS)
        $this.closest('li').removeClass(DIALOG_CHECKBOX_UNCHECKED_CLASS)
      }
    })

    $input.click(function (e) {
      const checkbox = this
      const $li = $(this).closest('li')

      if (checkbox.checked) {
        $li.addClass(DIALOG_CHECKBOX_CHECKED_CLASS)
        $li.removeClass(DIALOG_CHECKBOX_UNCHECKED_CLASS)
      } else {
        $li.removeClass(DIALOG_CHECKBOX_CHECKED_CLASS)
        $li.addClass(DIALOG_CHECKBOX_UNCHECKED_CLASS)
      }

      e.stopPropagation()
    })

    $li.click(function (e) {
      if (e.target.localName !== 'label') {
        const $input = $(this).find('input')
        const checkbox = $input[0]
        const $li = $(this)

        if (checkbox.checked) {
          $li.removeClass(DIALOG_CHECKBOX_CHECKED_CLASS)
          $li.addClass(DIALOG_CHECKBOX_UNCHECKED_CLASS)
          checkbox.checked = false
        } else {
          $li.addClass(DIALOG_CHECKBOX_CHECKED_CLASS)
          $li.removeClass(DIALOG_CHECKBOX_UNCHECKED_CLASS)
          checkbox.checked = true
        }

        e.stopPropagation()
      } else {
        // handled by $input.click handler
      }
    })
  }

  function getCommandButtonID ($this) {
    let id = '#'
    const checkboxID = $this.attr('id')
    const arr = checkboxID.split('-')

    for (let ii = 0; ii < arr.length - 1; ii++) {
      if (id.length > 1) {
        id += '-' + arr[ii]
      } else {
        id += arr[ii]
      }
    }

    return id
  }

  function applyCommandButtonLayout ($dialogForm) {
    const $list = $dialogForm.find('.list')
    const $checkbox = $list.find('input')

    // show / hide
    commandButtonVisibility($checkbox)

    // reorder
    reorderCommandButtons($checkbox)
  }

  function commandButtonVisibility ($checkbox) {
    $checkbox.each(function () {
      const $this = $(this)
      const id = getCommandButtonID($this)

      if (this.checked) {
        $(id).show()
      } else {
        $(id).hide()
      }
    })
  }

  function reorderCommandButtons ($checkbox) {
    let $prev = null
    const $controlGroup = $('.footer').find('.controlgroup')

    $checkbox.each(function () {
      const $this = $(this)
      const id = getCommandButtonID($this)

      if ($prev) {
        // move after previous
        $(id).insertAfter($prev)
      } else {
        // move to top
        $(id).prependTo($controlGroup)
      }

      $prev = $(id)
    })
  }

  function dialogStyle (id) {
    let $dialog

    var intervalID = setInterval(() => {
      if ($dialog) {
        // additional styling
        clearInterval(intervalID)

        const $titlebar = $dialog.find('.ui-dialog-titlebar')
        const $closeButton = $dialog.find('.ui-dialog-titlebar-close')
        const $buttonPane = $dialog.find('.ui-dialog-buttonpane')
        const $button = $('.ui-dialog-buttonset > button')

        $dialog.css({
          background: DIALOG_BACKGROUND
        })

        $titlebar.css({
          color: DIALOG_TITLEBAR_COLOR,
          background: DIALOG_TITLEBAR_BACKGROUND,
          borderColor: DIALOG_TITLEBAR_BORDER_COLOR
        })

        // $closeButton.hide();

        $buttonPane.css({
          background: DIALOG_BACKGROUND
        })

        $('.ui-widget-content').css({
          borderColor: DEVICE_BACKGROUND_COLOR
        })

        $button.css({
          color: 'black',
          borderColor: '#555',
          background: '#999'
        }).hover(function (e) {
          if (e.type === 'mouseenter') {
            $(this).css({
              borderColor: '#555',
              background: '#ddd'
            })
          } if (e.type === 'mouseleave') {
            $(this).css({
              borderColor: '#555',
              background: '#999'
            })
          }
        })

        $dialog.show()
        $dialog.focus()
      } else {
        // $dialog = $("div[role=dialog]");
        $dialog = $('#' + id).closest('div[role=dialog]')
        if (!$dialog.length) {
          $dialog = null
        }
      }
    }, 100)
  }

  function sendRemoteInput (str, $textarea, $message) {
    if (str.length) {
      $textarea.attr('placeholder', '')
      $textarea.val('')
      $textarea.focus()

      const obj = { input: str }

      app.server.write(obj, (err) => {
        $message.show()

        if (err) {
          $message.css('color', 'darkred')
          $message.text('Failed to send input to device').fadeOut(2000)
        } else {
          $message.css('color', '#555')
          $message.text('Remote input sent to device successfully').fadeOut(2000)
        }
      })
    } else {
      $textarea.val('')
      $textarea.attr('placeholder', REMOTE_INPUT_PLACEHOLDER)
      $textarea.focus()
    }
  }

  // public methods
  function about () {
    const $container = $('#dialog-container')
    let width = 500
    const id = 'dialog-about'
    const version = getAppVersion()
    const html =	'<div id="' + id + '" title="About" style="color: gray; text-align: center">' +
						'<h2>Honeywell Android Remote Control</h2>' +
						'<h2>Version: <span style="color: #555; padding: 5px">' + version + '</span></h2>' +
					'</div>'

    $container.empty()
    $container.html(html)

    if (app.win.width < width) {
      width = (app.win.width * 0.95)
    }

    $('#' + id).dialog({
      width,
      modal: true,
      closeOnEscape: true,
      buttons: {
        Ok () {
          $(this).dialog('close')
        }
      },
      close () {
        $('#' + id).closest('div[role=dialog]').remove()
      }
    })

    // $("#"+id).parent().hide();
    // dialogStyle(id);
  }

  function error (message, callback) {
    const customStyle = false
    const $container = $('#dialog-container')
    let { width } = app.win
    const id = 'dialog-error'
    const html =	'<div id="' + id + '" title="Error" style="color: #555; text-align: center">' +
						'<h2 class="dialog-message">' + message + '</h2>' +
					'</div>'

    $container.empty()
    $container.html(html)

    if (width > 700) {
      width = 700
    } else {
      width *= 0.95
    }

    /* if(customStyle){
			$("#"+id).dialog({
				width: width,
				modal: true,
				closeOnEscape: true,
				buttons: {
					Ok: function(){
						$(this).dialog("close");
					}
				},
				close: function() {
					$("#"+id).closest("div[role=dialog]").remove();

					if(callback){
						callback();
					}
				}
			}).parent().hide();

			dialogStyle(id);
		}else{
			$("#"+id).dialog({
				width: width,
				modal: true,
				closeOnEscape: true,
				buttons: {
					Ok: function(){
						$(this).dialog("close");
					}
				},
				close: function() {
					$("#"+id).closest("div[role=dialog]").remove();

					if(callback){
						callback();
					}
				}
			});
		} */
  }

  function remoteInputFormHTML (id) {
    const classes = 'ui-button ui-state-active'
    return '<div id="' + id + '" title="Remote Input">' +
						'<fieldset>' +
							'<legend>Options: </legend>' +
							'<div class="' + classes + '">' +
								'<input type="checkbox" name="checkbox-send-on-enter" id="checkbox-send-on-enter">' +
								'<label for="checkbox-send-on-enter">Send message when [Enter] is pressed' +
							'</div>' +
						'</fieldset>' +
						'<textarea placeholder="' + REMOTE_INPUT_PLACEHOLDER + '"></textarea>' +
						'<div class="message"></div>' +
					'</div>'
  }

  function remoteInputFormCheckboxHandler ($dialogForm) {
    const $label = $dialogForm.find('label')
    const $checkbox = $dialogForm.find('input')
    const $div = $checkbox.closest('div')

    $checkbox.each(function () {
      let enabled
      const $this = $(this)
      const id = $this.attr('id')

      if (id === 'checkbox-send-on-enter') {
        enabled = true
      }

      if (enabled) {
        this.checked = true
        $this.closest('div').addClass(DIALOG_CHECKBOX_CHECKED_CLASS)
        $this.closest('div').removeClass(DIALOG_CHECKBOX_UNCHECKED_CLASS)
      }
    })

    $checkbox.click(function (e) {
      const checkbox = this
      const $div = $(this).closest('div')

      if (checkbox.checked) {
        $div.addClass(DIALOG_CHECKBOX_CHECKED_CLASS)
        $div.removeClass(DIALOG_CHECKBOX_UNCHECKED_CLASS)
      } else {
        $div.removeClass(DIALOG_CHECKBOX_CHECKED_CLASS)
        $div.addClass(DIALOG_CHECKBOX_UNCHECKED_CLASS)
      }

      e.stopPropagation()
    })

    $div.click(function (e) {
      if (e.target.localName !== 'label') {
        const $input = $(this).find('input')
        const checkbox = $input[0]
        const $div = $(this)

        if (checkbox.checked) {
          $div.removeClass(DIALOG_CHECKBOX_CHECKED_CLASS)
          $div.addClass(DIALOG_CHECKBOX_UNCHECKED_CLASS)
          checkbox.checked = false
        } else {
          $div.addClass(DIALOG_CHECKBOX_CHECKED_CLASS)
          $div.removeClass(DIALOG_CHECKBOX_UNCHECKED_CLASS)
          checkbox.checked = true
        }

        e.stopPropagation()
      } else {
        // handled by $input.click handler
      }
    })

    $checkbox.hover(function (e) {
      if (e.type === 'mouseenter') {
        $(this).css({ cursor: 'pointer' })
      } if (e.type === 'mouseleave') {
        $(this).css({ cursor: 'default' })
      }
    })

    $label.hover(function (e) {
      if (e.type === 'mouseenter') {
        $(this).css({ cursor: 'pointer' })
      } if (e.type === 'mouseleave') {
        $(this).css({ cursor: 'default' })
      }
    })
  }

  function sizeRemoteInputFormTextarea ($dialogForm, $fieldset, $message, $textarea) {
    $message.text('Initializing...')

    const formHeight = parseInt($dialogForm.css('height'))
    const fieldsetHeight = parseInt($fieldset.css('height'))
    const messageHeight = parseInt($message.css('height'))
    let height = (formHeight - (fieldsetHeight + messageHeight))

    $message.hide()

    do {
      height -= 10

      $textarea.css({
        width: '100%',
        height,
        resize: 'none',
        boxSizing: 'content-box'
      })
    } while (!app.device.isOverflow($dialogForm[0]))

    height -= 50

    $textarea.css({
      width: '100%',
      height,
      resize: 'none',
      boxSizing: 'content-box'
    })
  }

  function remoteInputForm () {
    const $container = $('#dialog-container')
    const width = (parseInt($('.keypad-container').css('width')) * 0.99)
    const height = parseInt($('.keypad-container').css('height'))
    var id = 'dialog-remote-input'
    const html = remoteInputFormHTML(id)

    $container.empty()
    $container.html(html)

    const $dialogForm = $('#' + id)
    const $fieldset = $dialogForm.find('fieldset')
    const $message = $dialogForm.find('.message')
    const $textarea = $dialogForm.find('textarea')

    remoteInputFormCheckboxHandler($dialogForm)

    $dialogForm.find('fieldset').css({
      marginBottom: 10
    })

    startReceivingKeyboardInput($dialogForm)

    $dialogForm.dialog({
      width,
      height,
      closeOnEscape: false,
      position: {
        my: 'top',
        at: 'top',
        of: '.keypad-container'
      },
      buttons: {
        Send () {
          const str = $textarea.val()
          sendRemoteInput(str, $textarea, $message)
        },
        Close () {
          $(this).dialog('close')
        }
      },
      close () {
        stopReceivingKeyboardInput()
        $('div[role=dialog]').remove()
        app.displayKeypad = false
        $('.keypad-container').remove()
        app.device.resizeClientArea()
      }
    })

    $dialogForm.css('color', '#555')

    const $dialog = $dialogForm.closest('div[role=dialog]')
    const $buttonSet = $dialog.find('.ui-dialog-buttonset')
    const $sendButton = $buttonSet.find('button:first')

    $message.css({
      margin: 20,
      fontSize: 20,
      textAlign: 'center'
    })

    sizeRemoteInputFormTextarea($dialogForm, $fieldset, $message, $textarea)

    var id = setInterval(() => {
      if ($textarea.is(':visible')) {
        clearInterval(id)
        $textarea.focus()
      }
    }, 100)
  }

  function commands (buttons = commandButtonList()) {
    let height
    let width = 725
    const $container = $('#dialog-container')
    const id = 'dialog-command-buttons'
    const html = commandButtonHTML(buttons, id)

    if (app.displayKeypad) {
      if (app.device.isScreenSplitPortrait()) {
        // portrait
        const height1 = parseInt($('#device-container').css('height'))
        const height2 = parseInt($('.keypad-container').css('height'))

        height = (height1 + height2)
      } else {
        // landscape
      }
    }

    if (!height) {
      height = parseInt($('#device-container').css('height'))
    }

    if (app.win.width < width) {
      // width = (app.win.width * 0.95);
      width = app.win.width
    }

    $container.empty()
    $container.html(html)

    const $dialogForm = $('#' + id)

    $dialogForm.find('fieldset').css({
      marginBottom: 10
    })

    $dialogForm.find('button').hover(function (e) {
      if (e.type === 'mouseenter') {
        $(this).css({ cursor: 'pointer' })
        $(this).addClass('ui-state-active')
      } if (e.type === 'mouseleave') {
        $(this).css({ cursor: 'default' })
        $(this).removeClass('ui-state-active')
      }
    })

    $dialogForm.dialog({
      width,
      height,
      position: { at: 'top' },
      modal: true,
      closeOnEscape: false,
      buttons: {
        Apply () {
          applyCommandButtonLayout($dialogForm)
          app.commands.saveButtonLayout()

          setTimeout(() => {
            app.device.resizeClientArea()
          }, 500)

          $(this).dialog('close')
        },
        Cancel () {
          $(this).dialog('close')
        }
      },
      close () {
        const $dialogContainer = $('#dialog-container')
        const $dialog = $dialogForm.closest('div[role=dialog]')

        $dialog.remove()
        $dialogContainer.empty()
      }
    })
    /*
		$dialogForm.css({
			overflow: "hidden"
		});
*/
    manageButtons($dialogForm)
    manageCommandButtonList($dialogForm)
  }

  function getCommandButtonsFromForm (id) {
    const buttons = []
    const $dialogForm = $('#' + id)
    const $li = $dialogForm.find('li')

    $li.each(function () {
      const $this = $(this)
      const $label = $this.find('label')
      const $input = $this.find('input')
      const input = $input[0]

      buttons.push({
        id: $input.attr('id'),
        name: $label.text(),
        enabled: input.checked
      })
    })

    return buttons
  }

  function receiveKeyboardInput (e) {
    if ($receiveInput) {
      const $dialogForm = $receiveInput
      const $textarea = $dialogForm.find('textarea')

      if ($textarea.is(':focus')) {
        if (!e.ctrlKey && !e.altKey) {
          const { key } = e
          const code = e.which

          if (code === 13) {
            // enter pressed
            const $checkbox = $('#checkbox-send-on-enter')
            const checkbox = $checkbox[0]

            if (checkbox.checked) {
              e.preventDefault()

              let str = $textarea.val()

              if (str.length) {
                str += '\n'

                const $message = $dialogForm.find('.message')
                sendRemoteInput(str, $textarea, $message)
              }
            }
          }
        }
        return true
      }
    }
    return false
  }

  // public API
  return {
    about,
    error,
    closeDialog,
    commands,
    remoteInputForm,
    receiveKeyboardInput,
    getCommandButtonsFromForm
  }
}())
