import $ from 'jquery'
import app from './app'

export default app.tcpServer = (function () {
  const USE_TLS = false
  const MAX_HEADER_SIZE = 16
  const IMAGE_BASE64_ENCODED = false
  const STATE_RX_HEADER = 0
  const STATE_RX_INFO = 1
  const STATE_RX_IMAGE = 2

  let rx = {
    index: 0,
    images: 0,
    queue: [],
    state: STATE_RX_HEADER,
    length: MAX_HEADER_SIZE
  }
  let image = {}
  let pfx = null
  let passphrase = null
  let screenCapture
  let stateMachineIntervalID = null
  let imageProcessingIntervalID = null
  let busy = false
  const record = {
    queue: null,
    start: null
  }
  const queueProcessing = { start: null }
  let disconnectedTimer = null
  const device = {
    socket: null,
    cmdSocket: null,
    receiveChannel: null,
    address: {
      remote: null
    }
  }
  let totalBytes = 0
  $(document).ready(() => {
    // resetState()
  })

  // private methods
  function stateMachine () {
  // console.log('In stateMachine')
    if (!busy) {
      // console.log('In stateMachine > ', busy)
      busy = true

      if (!stateMachineIntervalID) {
        stateMachineIntervalID = setInterval(() => {
          if (!busy) {
            // console.log("restart state machine");
            stateMachine()
          }
        }, 1000)
      }

      for (;;) {
        const length = bufferedDataLength()

        if (length >= rx.length) {
          // enough data received for current state
          switch (rx.state) {
            case STATE_RX_HEADER: rxHeader(); break
            case STATE_RX_INFO: rxInfo(); break
            case STATE_RX_IMAGE: rxImage(); break
          }
        } else {
          // wait for more data to be received
          busy = false
          break
        }
      }
    } else {
      // console.log("state machine is currently busy");
    }
  }

  function resetState () {
    if (device.socket) {
      device.socket.destroy()
      device.socket = null
    }

    image = {}

    rx = {
      index: 0,
      images: 0,
      queue: [],
      state: STATE_RX_HEADER,
      length: MAX_HEADER_SIZE
    }
  }

  function bufferedDataLength () {
    let length = 0
    const buffers = rx.queue.length

    for (let ii = 0; ii < buffers; ii++) {
      length += rx.queue[ii].length
    }

    length -= rx.index

    return length
  }

  function getNextRxByte () {
    if (rx.queue.length) {
      if (rx.index < rx.queue[0].length) {
        // more data available in current buffer
        return rx.queue[0][rx.index++]
      } else {
        rx.index = 0
        rx.queue.splice(0, 1)

        if (rx.queue.length && rx.queue[0].length) {
          // next buffer
          return rx.queue[0][rx.index++]
        }
      }
    }
    return null
  }

  function rxHeader () {
    // app.log.info("Header received");

    const version = getNextRxByte()
    const type = getNextRxByte()

    if (app.frame.protocolVersion() === version) {
      const obj = { buf: [], index: 0 }

      if (app.frame.isImage(type)) {
        image = { buffer: null, full: 1, width: 0, height: 0 }

        // image length (long)
        obj.buf.push(getNextRxByte())
        obj.buf.push(getNextRxByte())
        obj.buf.push(getNextRxByte())
        obj.buf.push(getNextRxByte())

        // get full image indicator (byte)
        obj.buf.push(getNextRxByte())

        // get image width (short)
        obj.buf.push(getNextRxByte())
        obj.buf.push(getNextRxByte())

        // get image height (short)
        obj.buf.push(getNextRxByte())
        obj.buf.push(getNextRxByte())

        // convert from network by order
        rx.length = app.frame.popLong(obj)
        image.full = app.frame.popByte(obj)
        image.width = app.frame.popShort(obj)
        image.height = app.frame.popShort(obj)

        // console.log("image header, image length: ", rx.length);
        // console.log("image header, full image: ", image.full);
        // console.log("image header, image width: ", image.width);
        // console.log("image header, image: height", image.height);

        // transition to receive image state
        rx.state = STATE_RX_IMAGE
      } else if (app.frame.isInfo(type)) {
        // get length of info object (short)
        obj.buf.push(getNextRxByte())
        obj.buf.push(getNextRxByte())

        // convert from network by order
        rx.length = app.frame.popShort(obj)

        // transition to receive info state
        rx.state = STATE_RX_INFO
      }
    }
  }

  function rxInfo () {
    app.log.info('Device information received')

    let str = ''

    for (let ii = 0; ii < rx.length; ii++) {
      str += String.fromCharCode(getNextRxByte())
    }

    try {
      var obj = JSON.parse(str)
      app.log.info('Device info JSON' + str)
    } catch (e) {
      app.log.error('rxInfo: failed to parse JSON -- ', e.message)
    }
    if (obj) {
      const { sku } = obj
      const { model } = obj
      const { deviceName } = obj

      app.log.info('SKU: ' + obj.SKU)
      app.log.info('model: ' + obj.model)
      app.log.info('name: ' + obj.deviceName)

      app.device.setInfo(obj)
      app.device.setModel(model)
      app.device.setWindowTitle(deviceName + ' [' + app.device.getAddress() + ']')

      if (app.debug && obj.adb === true) {
        app.adb = true
        app.log.info('ADB client detected')
      }
    }

    // transition to receive header state
    rx.state = STATE_RX_HEADER
    rx.length = MAX_HEADER_SIZE
  }

  function rxImage () {
    // if (rx.length < 0) return

    const buffer = new ArrayBuffer(rx.length)
    const arr = new Uint8Array(buffer)
    const a = []
    for (let ii = 0; ii < rx.length; ii++) {
      const byte = getNextRxByte()
      arr[ii] = byte
    }
    image.buffer = buffer
    // console.log('imageProcessingIntervalID '+imageProcessingIntervalID)
    if (!imageProcessingIntervalID) {
      imageQueueProcessing()
    }

    app.imageQueue.push(image)

    if (image.full) {
      $('#canvas-box').css({ display: 'block' })
      $('#failureMsg').css({ display: 'none' })
      $('#user-msg').css({ display: 'none' })
      $('#loading-icon').css({ display: 'none' })
      $('#deviceMsg').css({ display: 'none' })
      // console.log("full image received: ", rx.length);
    } else {
      // console.log("xor image received: ", rx.length);
    }

    // console.log("images queued: ", app.imageQueue.length);

    rx.images++
    rx.state = STATE_RX_HEADER
    rx.length = MAX_HEADER_SIZE
  }

  function imageQueueProcessing () {
    imageProcessingIntervalID = setInterval(() => {
      if (screenCapture && !screenCapture.images.length) {
        captureScreen()
      }
      if (queueProcessing.start) {
        const now = new Date().getTime()
        const elapsed = (now - queueProcessing.start)

        if (elapsed > 500) {
          queueProcessing.start = null
        }
      }
      if (app.imageQueue.length && !queueProcessing.start) {
        queueProcessing.start = new Date().getTime()

        // update screen with image
        app.device.updateScreen(app.imageQueue[0], () => {
          // console.log("image processing: ", (new Date().getTime() - queueProcessing.start));

          if (screenCapture && screenCapture.images.length < screenCapture.count) {
            captureScreen()
          }

          app.imageQueue.splice(0, 1)
          queueProcessing.start = null
        })
      }
    }, 20)
  }

  function captureScreen () {
    let base64Data
    const captureXOR = false
    const ctx = app.device.getCanvasContext()

    if (ctx.canvas.height && ctx.canvas.width) {
      base64Data = ctx.canvas.toDataURL('image/png').replace(/^data:image\/png;base64,/, '')
      screenCapture.images.push(base64Data)
    }

    /* if (captureXOR) {
      ctx = app.device.getCanvasContextXOR()

      if (ctx.canvas.height && ctx.canvas.width) {
        base64Data = ctx.canvas.toDataURL('image/png').replace(/^data:image\/png;base64,/, '')
        screenCapture.images.push(base64Data)
      }
    } */
  }

  function exception (message) {
    this.message = message
    this.name = 'TcpServerException'
  }

  function connectionError (error, socket) {
    // prepare to receive next image
    resetState()
    app.log.info('Connection ' + socket.remoteAddress + ' error: ' + error.message)

    app.device.disconnected()
  }

  function connectionClose (socket) {
    // prepare to receive next image
    resetState()
    app.log.info('connection from ' + socket.remoteAddress + ' closed')

    app.device.disconnected()
  }

  let prevBinary = null
  let totalImages = 0
  let binaryStr = ''

  function webRTCmessage (data) {
    // console.log(data)
    totalImages++
    // console.log(data)
    // data=JSON.stringify(data)
    // clearTimeout(disconnectedTimer);
    // disconnectedTimer = setTimeout(function(){ $("#deviceMsg").css({ display: "block" });$("canvas").css({ display: "none" })}, 60000);
    // data = JSON.parse(data)
    totalBytes = parseInt(totalBytes) + parseInt(getBinarySize(data.imageData))
    const datap = {}
    datap.device = app.deviceId
    datap.bytes = totalBytes / 1000
    datap.images = totalImages
    const deviceDate = new Date(data.timestamp)
    datap.dDate = deviceDate.toUTCString()
    const remoteDate = new Date()
    datap.rDate = remoteDate.toUTCString()
    if (data.imageType == 'true') {
      binaryStr += data.imageData
    } else {
      binaryStr += data.imageData
      // device.receiveChannel.publish(datap);

      const binary = fixBinary(atob(binaryStr))
      if (prevBinary !== binary) {
        rx.queue.push(binary)
        prevBinary = binary
        binaryStr = ''
      }

      stateMachine()
    }
  }

  function buttonInit () {
    $('.footer').show()
    app.commands.initButtons()
  }

  function serverConnection (socket) {
		 $('.footer').show()
    app.commands.initButtons()

    /* if(device.address.remote){
			if(device.address.remote !== socket.remoteAddress){
				socket.destroy();
				return;
			}
		}else{
			device.address.remote = socket.remoteAddress;

			var addr = socket.remoteAddress.split(":");

			for(var ii = 0; ii < addr.length; ii++){
				if(addr[ii].indexOf(".") != -1){
					app.device.setAddress(addr[ii]);
					break;
				}
			}
		} */

    device.socket = socket
    image = {}
    socket.watch((data) => {
      totalImages++
      // console.log(data)
      // data=JSON.stringify(data)
      clearTimeout(disconnectedTimer)
      disconnectedTimer = setTimeout(() => { $('#deviceMsg').css({ display: 'block' }); $('canvas').css({ display: 'none' }) }, 60000)
      data = JSON.parse(data)
      totalBytes = parseInt(totalBytes) + parseInt(getBinarySize(data.imageData))
      const datap = {}
      datap.device = app.deviceId
      datap.bytes = totalBytes / 1000
      datap.images = totalImages
      const deviceDate = new Date(data.timestamp)
      datap.dDate = deviceDate.toUTCString()
      const remoteDate = new Date()
      datap.rDate = remoteDate.toUTCString()

      device.receiveChannel.publish(datap)

			 const binary = fixBinary(atob(data.imageData))
      if (prevBinary != binary) {
        	rx.queue.push(binary)
			 prevBinary = binary
      }

      stateMachine()
      	})
    socket.on('error', (error) => {
      connectionError(error, socket)
    })
    socket.once('close', () => {
      connectionClose(socket)
    })
  }

  function sendButtonKey (code, str) {
    const obj = { key: code, code }

    write(obj, () => {})
  }

  function sendRecordedEvent (index) {
    if (index < record.queue.length) {
      const currentEvent = record.queue[index]
      const previousEvent = record.queue[index - 1]
      const timeout = (currentEvent.elapsed - previousEvent.elapsed)

      setTimeout(() => {
        write(record.queue[index], () => {
        })

        sendRecordedEvent(++index)
      }, timeout)
    }
  }

  // public methods
  function stop () {
    if (device.socket) {
      /*
			app.commands.send(COMMAND_DISCONNECT, function(){
				device.socket.destroy();
				device.socket = null;
			});
*/
      device.socket.destroy()
      device.socket = null
    }
  }

  // http://vanilla-js.blogspot.com/2017/09/nodejs-tls-socket-connection-full.html
  function start (port = 80) {
    app.log.info('starting TCP server on port ' + port)
    let server
    if (USE_TLS) {
      try {
        // secure connection
        // const tls = require("tls");
        const tls = null
        if (pfx.length && passphrase.length) {
          var options = {
            pfx,
            passphrase,
            rejectUnauthorized: false
          }
        } else {
          /* var options = {
						key: app.fs.readFileSync("certs/server-key.pem"),
						cert: app.fs.readFileSync("certs/server-crt.pem"),
						ca: app.fs.readFileSync("certs/ca-crt.pem"), // authority chain for the clients
						requestCert: true, // ask for a client cert
						rejectUnauthorized: true // act on unauthorized clients at the app level
					};		 */
        }
        // if (tls !== null) {
        //   server = tls.createServer(options, (socket) => {
        //   })

        //   if (server) {
        //     app.log.info('TLS server running')
        //     server.on('secureConnection', (socket) => {
        //     // c.authorized will be true if the client cert presented validates with our CA
        //       app.log.info('secure connection -- client authorized: ', socket.authorized)

        //       serverConnection(socket)
        //     })
        //   }
        // }
      } catch (e) {
        app.log.error('TLS exception: ', e.message)
      }
    } else {
      // non-secure connection
      // const net = require("net");
      // var server = net.createServer();
    }

    server.on('connection', (socket) => {
      // unsecured connection

      const remoteAddress = socket.remoteAddress + ':' + socket.remotePort
      app.log.info('new client connection from ' + remoteAddress)

      // serverConnection(socket);
    })

    server.listen(port, () => {
      const addr = server.address()
      app.log.info('server listening to ' + addr.address + ':' + addr.port + ' family: ' + addr.family)
    })
  }

  function connection () {
    return (device.socket !== null)
  }

  function playbackRecording () {
    // first make sure recording is stopped before playing
    stopRecording()

    if (record.queue && record.queue.length) {
      let index = 0

      // send first event
      write(record.queue[index++], () => {})

      sendRecordedEvent(index)
    }
  }

  function startRecording () {
    record.queue = []
    record.start = app.timer.start()
  }

  function stopRecording () {
    record.start = 0
  }

  function write (obj, callback) {
    const error = true

    if (obj) {
      const buf = app.frame.objectToBuffer(obj)
      app.wrtclientsendData(buf.toString())
      /* device.cmdSocket.publish(buf.toString(),function(){
					// success
					console.log("buffer sent successfully");
					callback();
				}, function(){
					// error
					console.log("failed to send buffer");
					callback(error);
				}) */

      if (record.start) {
        obj.elapsed = app.timer.elapsed(record.start)
        record.queue.push(obj)
      }
    } else {
      callback(error)
    }
  }

  function getImagesFromCanvas (count, callback) {
    if (!screenCapture) {
      screenCapture = { images: [], count }

      var id = setInterval(() => {
        if (screenCapture.images.length === screenCapture.count) {
          clearInterval(id)
          callback(screenCapture.images)
        }
      }, 100)
    }
  }

  function releaseImagesFromCanvas () {
    screenCapture = null
  }

  function pfxFile (data) {
    pfx = data
  }

  function pfxPassphrase (str) {
    passphrase = str
  }

  function subscribeChannels (did, socket) {
    const imageChannel = socket.subscribe(did)
    imageChannel.on('subscribeFail', () => {})
    device.cmdSocket = socket.subscribe(did + 'S')
    device.receiveChannel = socket.subscribe(did + 'DR')
    serverConnection(imageChannel)
    socket.on('clientDisconnect', () => {})
  }

  function fixBinary (bin) {
    const { length } = bin
    const a = []
    for (let i = 0; i < length; i++) {
			  a.push(bin.charCodeAt(i))
    }
    return a
  }

  function getBinarySize (string) {
    return (new TextEncoder().encode(string)).length
  }

  function restartImageProcessing () {
    resetState()
    busy = false
  }

  // public API
  return {
    stop,
    start,
    write,
    pfxFile,
    connection,
    pfxPassphrase,
    stopRecording,
    startRecording,
    playbackRecording,
    getImagesFromCanvas,
    releaseImagesFromCanvas,
    subscribeChannels,
    webRTCmessage,
    buttonInit,
    restartImageProcessing
  }
}())
