import React, { useEffect, useRef, useState, useCallback } from 'react'
import { toast } from 'react-toastify'

import { findFileType } from 'Utils/file-type'
import { WarningToast } from 'Themes/ScufStyledComponents'
import {
  StyledDataTable,
  StyledButton,
  StyledIcon
} from './folder-grid.styles'

const { Item } = StyledDataTable.ActionBar
const columns = [
  { field: 'name', header: 'File Name', render: Render },
  { field: 'datemodified', header: 'Date Modified', render: RenderDateModified },
  { field: 'fileType', header: 'Type', initialWidth: '25%' },
  { field: 'sizeInBytes', header: 'File Size', initialWidth: '15%', render: RenderSize }
]

function Render ({ value }) {
  return <p>{value}</p>
}

function RenderDateModified ({ rowData }) {
  const { datemodifiedInFormat } = rowData
  return <p>{datemodifiedInFormat}</p>
}

function RenderSize ({ rowData }) {
  const { type, sizeInFormat } = rowData
  if (type === 'folder') return <p />
  return <p>{sizeInFormat}</p>
}

const FileUploader = ({ handleFileUpload, uploadingInProgress }) => {
  const hiddenFileInput = useRef(null)

  const handleButtonClick = event => {
    if (uploadingInProgress) {
      toast(<WarningToast message='Uploading file is in progress, Please wait.' />)
      return
    }
    hiddenFileInput.current.click()
  }

  const handleChange = event => {
    const fileUploaded = event.target.files[0]
    handleFileUpload(fileUploaded)
    event.target.value = null
  }

  return (
    <>
      <StyledButton className='item' data-command='1' onClick={handleButtonClick}>
        {/* <StyledIcon name='file-upload' size='small' root='global' /> */}
        <StyledIcon root='building' name='file-download' size='medium' flipped='vertically' />
        <span className='content-wrap'>Upload</span>
      </StyledButton>
      <input type='file' ref={hiddenFileInput} style={{ display: 'none' }} onChange={handleChange} />
    </>
  )
}

function handleSearchInput (e) {
  const { value } = e.target
  if (value.length > 0) {
    document.querySelector('.ui.fitted.checkbox.select-all-checkbox').style.display = 'none'
  } else {
    document.querySelector('.ui.fitted.checkbox.select-all-checkbox').style.display = 'inline-block'
  }
}

const FolderTable = ({
  childrenFiles,
  selectedChildrens,
  setSelectedChildrens,
  setIsDeleteModalOpen,
  uploadingInProgress,
  handleUploadCallBack,
  handleDownloadCallback
}) => {
  const [rows, setRows] = useState([])
  const searchInputRef = useRef()

  useEffect(() => {
    searchInputRef.current && searchInputRef.current.removeEventListener('input', handleSearchInput, false)
    searchInputRef.current = document.querySelector('.input-wrap .input-box')
    searchInputRef.current && searchInputRef.current.addEventListener('input', handleSearchInput, false)
  })

  useEffect(() => {
    const fileList = childrenFiles.map(file => {
      const { name, datemodifiedInFormat, type, sizeInFormat, format, sizeInBytes, datemodified } = file
      const fileType = findFileType(type, format)
      return {
        name,
        type,
        fileType,
        datemodifiedInFormat,
        sizeInFormat,
        sizeInBytes,
        datemodified
      }
    })
    setRows(fileList)
  }, [childrenFiles])

  const handleSorting = useCallback(
    ({ sortField, sortOrder }) => {
      if (sortField === 'sizeInBytes') {
        const rowsWithoutFolderTypes = rows.filter(row => row.fileType !== 'Folder')
        let rowsWithFolder = rows.filter(row => row.fileType === 'Folder')
        if (!sortOrder) {
          const maxFileSize = Math.max(...rowsWithoutFolderTypes.map((o) => { return o.sizeInBytes }))
          rowsWithFolder = rowsWithFolder.map(row => ({
            ...row,
            sizeInBytes: maxFileSize + 1
          }))
        } else {
          const minFileSize = Math.min(...rowsWithoutFolderTypes.map((o) => { return o.sizeInBytes }))
          rowsWithFolder = rowsWithFolder.map(row => ({
            ...row,
            sizeInBytes: minFileSize - 1
          }))
        }

        const rowsClone = [...rowsWithoutFolderTypes]
        // rowsClone.sort((a, b) => a.sizeInBytes - b.sizeInBytes)
        // const testAllRows = [...rowsClone, ...rowsWithFolder]
        setRows([...rowsClone, ...rowsWithFolder])
      }
    },
    [rows]
  )

  const handleSelection = useCallback((rows) => {
    setSelectedChildrens(rows)
  }, [setSelectedChildrens])

  return (
    <StyledDataTable
      data={rows || []}
      search={true}
      searchPlaceholder='Search for files'
      selection={selectedChildrens}
      selectionMode='multiple'
      onSelectionChange={handleSelection}
      onSort={handleSorting}
    >
      <StyledDataTable.HeaderBar>
        <StyledDataTable.HeaderBar.Item
          iconSize='small'
          icon={<FileUploader handleFileUpload={handleUploadCallBack} uploadingInProgress={uploadingInProgress} />}
        />
      </StyledDataTable.HeaderBar>
      {columns
        .map(key =>
          <StyledDataTable.Column
            key={key.field}
            field={key.field}
            initialWidth={key.initialWidth}
            renderer={key.render}
            header={key.header}
            sortable={true}
          />)}
      <StyledDataTable.ActionBar clearSelectionOnClose={true}>
        <Item
          icon='file-download'
          iconSize='small'
          content='Download'
          onClick={handleDownloadCallback}
        />
        <Item
          icon='delete'
          iconSize='small'
          content='Delete'
          onClick={() => setIsDeleteModalOpen(true)}
        />
      </StyledDataTable.ActionBar>
    </StyledDataTable>
  )
}

export default React.memo(FolderTable)
