export const getQueryParams = (params, url) => {
  const href = url
  const reg = new RegExp('[?&]' + params + '=([^&#]*)', 'i')
  const queryString = reg.exec(href)
  return queryString ? queryString[1] : null
}

export function parseJwt (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export function getAccessTokenDetails () {
  if (sessionStorage && sessionStorage.getItem('accessToken')) {
    const accessToken = sessionStorage.getItem('accessToken')
    return parseJwt(accessToken)
  }

  return null
}

export const deviceId = getQueryParams('deviceId', window.location.href)
