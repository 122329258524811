import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import config from './AppConfig'

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: config.tracing.appInsights,
    loggingLevelConsole: 1,
    enableAutoRouteTracking: true,
    enableCorsCorrelation: false,
    disableFetchTracking: false,
    maxBatchInterval: 0
  }
})

appInsights.snippet.queue.push(() => {
  appInsights.addTelemetryInitializer((envelope) => {
    envelope.tags['ai.cloud.role'] = 'Front End RV'
  })
})

appInsights.loadAppInsights()
appInsights.trackPageView()

export const trackEvent = (eventDetails) => appInsights.trackEvent(eventDetails)
export const getAppInsights = () => appInsights
// export default appInsights
