import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

import { FileExplorerTypes } from './actions'
import { DEFAULT_ACTIVE_FOLDER_PATH } from 'Utils/constants'
import { findNode } from 'Utils/find-node'

export const INITIAL_STATE = Immutable({
  loading: false,
  deviceManufacturerInfo: 'null',
  isFileExplorerAvailable: false,
  agentVersion: '',
  data: [],
  provisioningMode: false,
  activeFolderDetails: {
    id: '',
    name: '',
    path: '',
    childrenFiles: []
  },
  currentUploadingDetails: {
    fileName: '',
    inProgress: false,
    failedStatus: false
  },
  currentDownloadingDetails: {
    fileName: '',
    inProgress: false,
    failedStatus: false,
    downloadPercentage: -1
  },
  currentDeletingDetails: {
    actualPath: '',
    fileName: ''
  },
  uploadWaitingResponseDetails: []
})

const updateFileExplorerLoading = (state, { loading }) => state.merge({ loading })

const updateDeviceManufacturerInfo = (state, { deviceManufacturerInfo }) => state.merge({ deviceManufacturerInfo })

const updateFileExplorerAvailability = (state, { isFileExplorerAvailable, agentVersion }) => state.merge({ isFileExplorerAvailable, agentVersion })

const updateFileExplorerData = (state, { data, provisioningMode }) => {
  const { activeFolderDetails } = state
  let updateActiveFolderDetails = {}
  if (activeFolderDetails.path === '') {
    for (const folder of data) {
      if (folder.path === DEFAULT_ACTIVE_FOLDER_PATH) {
        updateActiveFolderDetails = {
          name: folder.name,
          path: folder.path,
          childrenFiles: folder.children
        }
        return state.merge({ data, provisioningMode, activeFolderDetails: updateActiveFolderDetails, loading: false })
      }
    }
  } else {
    const { path } = activeFolderDetails
    const node = findNode(path, data)
    if (node) {
      return state.merge({ data, provisioningMode, activeFolderDetails: { childrenFiles: node.children } }, { deep: true })
    }
  }
  return state.merge({ data, provisioningMode, activeFolderDetails: INITIAL_STATE.activeFolderDetails })
}
const updateUploadOrDeleteResponse = (state, { data, childrenFiles, path }) => {
  const { activeFolderDetails } = state

  if (path === activeFolderDetails.path) {
    if (childrenFiles) {
      return state.merge({ data, activeFolderDetails: { childrenFiles } }, { deep: true })
    } else {
      return state.merge({ data, activeFolderDetails: INITIAL_STATE.activeFolderDetails })
    }
  } else {
    return state.merge({ data })
  }
}

const updateDeleteResponse = (state, { data, childrenFiles, path }) => {
  const { activeFolderDetails } = state
  const { currentDeletingDetails: { actualPath } } = state

  if (path === activeFolderDetails.path) {
    if (childrenFiles) {
      return state.merge({ data, activeFolderDetails: { childrenFiles }, currentDeletingDetails: INITIAL_STATE.currentDeletingDetails }, { deep: true })
    } else {
      return state.merge({ data, activeFolderDetails: INITIAL_STATE.activeFolderDetails, currentDeletingDetails: INITIAL_STATE.currentDeletingDetails })
    }
  } else if (actualPath === activeFolderDetails.path) {
    return deleteActiveFolderFromTree(state, data)
  } else {
    return state.merge({ data, currentDeletingDetails: INITIAL_STATE.currentDeletingDetails })
  }
}

function deleteActiveFolderFromTree (state, data) {
  return state.merge({ data, activeFolderDetails: INITIAL_STATE.activeFolderDetails, currentDeletingDetails: INITIAL_STATE.currentDeletingDetails })
}

const updateSelectedFolder = (state, { folderDetails }) => state.merge({ activeFolderDetails: folderDetails })
const startUploading = (state, { fileName }) => state.merge({ currentUploadingDetails: { fileName, inProgress: true, failedStatus: false } })
const uploadingCompleted = (state, { fileName }) => state.merge({ currentUploadingDetails: { fileName: '', inProgress: false, failedStatus: false }, uploadWaitingResponseDetails: state.uploadWaitingResponseDetails.filter(name => name !== fileName) }, { deep: true })
const uploadingFailed = (state, { fileName }) => state.merge({ currentUploadingDetails: { fileName: '', inProgress: false, failedStatus: true }, uploadWaitingResponseDetails: state.uploadWaitingResponseDetails.filter(name => name !== fileName) })
const resetCurrentUploadingDetails = (state) => state.merge({ currentUploadingDetails: INITIAL_STATE.getIn(['currentUploadingDetails']) })
const insertUploadWaitingResponse = (state, { fileName }) => state.merge({ uploadWaitingResponseDetails: [...state.uploadWaitingResponseDetails, fileName] })
const removeUploadWaitingResponse = (state, { fileName }) => state.merge({ uploadWaitingResponseDetails: state.uploadWaitingResponseDetails.filter(name => name !== fileName), currentUploadingDetails: { fileName: '', inProgress: false, failedStatus: true } })
const startDownloading = (state, { fileName }) => state.merge({ currentDownloadingDetails: { fileName, inProgress: true, failedStatus: false, downloadPercentage: 0 } })
const downloadingCompleted = (state) => state.merge({ currentDownloadingDetails: { inProgress: false, failedStatus: false } }, { deep: true })
const downloadingFailed = (state) => state.merge({ currentDownloadingDetails: { inProgress: false, failedStatus: true } }, { deep: true })
const resetCurrentDownloadingDetails = (state) => state.merge({ currentDownloadingDetails: INITIAL_STATE.getIn(['currentDownloadingDetails']) })
const updateDownloadingPercentage = (state, { downloadPercentage }) => state.merge({ currentDownloadingDetails: { downloadPercentage } }, { deep: true })
const endOfDownloading = (state) => state.merge({ currentDownloadingDetails: INITIAL_STATE.getIn(['currentUploadingDetails']) })
const updateDeletingDetails = (state, { actualPath, fileName }) => state.merge({ currentDeletingDetails: { actualPath, fileName } })

export const fileExplorerReducer = createReducer(INITIAL_STATE, {
  [FileExplorerTypes.UPDATE_FILE_EXPLORER_LOADING]: updateFileExplorerLoading,
  [FileExplorerTypes.UPDATE_DEVICE_MANUFACTURER_INFO]: updateDeviceManufacturerInfo,
  [FileExplorerTypes.UPDATE_FILE_EXPLORER_AVAILABILITY]: updateFileExplorerAvailability,
  [FileExplorerTypes.UPDATE_FILE_EXPLORER_DATA]: updateFileExplorerData,
  [FileExplorerTypes.UPDATE_SELECTED_FOLDER]: updateSelectedFolder,
  [FileExplorerTypes.UPDATE_UPLOAD_OR_DELETE_RESPONSE]: updateUploadOrDeleteResponse,
  [FileExplorerTypes.UPDATE_DELETE_RESPONSE]: updateDeleteResponse,
  [FileExplorerTypes.START_UPLOADING]: startUploading,
  [FileExplorerTypes.UPLOADING_COMPLETED]: uploadingCompleted,
  [FileExplorerTypes.UPLOADING_FAILED]: uploadingFailed,
  [FileExplorerTypes.RESET_CURRENT_UPLOADING_DETAILS]: resetCurrentUploadingDetails,
  [FileExplorerTypes.INSERT_UPLOAD_WAITING_RESPONSE]: insertUploadWaitingResponse,
  [FileExplorerTypes.REMOVE_UPLOAD_WAITING_RESPONSE]: removeUploadWaitingResponse,
  [FileExplorerTypes.START_DOWNLOADING]: startDownloading,
  [FileExplorerTypes.DOWNLOADING_COMPLETED]: downloadingCompleted,
  [FileExplorerTypes.DOWNLOADING_FAILED]: downloadingFailed,
  [FileExplorerTypes.RESET_CURRENT_DOWNLOADING_DETAILS]: resetCurrentDownloadingDetails,
  [FileExplorerTypes.UPDATE_DOWNLOADING_PERCENTAGE]: updateDownloadingPercentage,
  [FileExplorerTypes.END_OF_DOWNLOADING]: endOfDownloading,
  [FileExplorerTypes.UPDATE_DELETING_DETAILS]: updateDeletingDetails
})
