import { all, fork } from 'redux-saga/effects'

import { remoteScreenSagas } from '../Features/RemoteScreen'
// import { fileExplorerSagas } from '../Features/FileExplorer'

export default function * root () {
  yield all([
    fork(remoteScreenSagas)
  ])
}
