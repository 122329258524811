import $ from 'jquery'
import app from './app'

export default app.screen = (function(){
	"use strict";

	const KEY = "rcScreenCaptureDirectory";
	
	$(document).ready(function(){
		// debug only!!
		//app.storage.removeItem(KEY);
	});
	
	// private methods
	function getScreenCaptureDir(){
		var obj = app.storage.getItem(KEY);
		
		if(obj && obj.dir){
			return obj.dir;
		}
		return null;
	}
	
	function saveScreenCapture(directory, data, callback){
		var date = new Date().toISOString().replace(/:/g, "-"),
			path = directory + "/image-" + date + ".png";
		
		app.fs.writeFile(path, data, "base64", function(err){
			
			callback();
		});		
	}
	
	function captureScreen(dir, number, callback){
//		$("#screen-capture-button").button("disable");
		
		if(app.debug && app.adb){
			var ctx = app.device.getCanvasContext();
			
			if(ctx.canvas.height && ctx.canvas.width){
				var base64Data = ctx.canvas.toDataURL("image/png").replace(/^data:image\/png;base64,/, "");
				saveScreenCapture(dir, base64Data, callback)
			}
		}else{
			app.tcpServer.getImagesFromCanvas(number, function(arr){
				if(arr){
					var ii = 0, busy = false;
						
					var id = setInterval(function(){
						if(!busy){
							busy = true;
							saveScreenCapture(dir, arr[ii++], function(){
								if(ii < arr.length){
									busy = false;
								}else{
									clearInterval(id);
									
									app.tcpServer.releaseImagesFromCanvas();
									//$("#screen-capture-button").button("enable");			
									
									callback();
								}
							});
						}
					}, 50);
				}
			});
		}
	}

	// public methods
	function capture(callback){
		var ctx = app.device.getCanvasContext();
		
		if(ctx.canvas.height && ctx.canvas.width){
			var numberOfImages = 1,
				dir = getScreenCaptureDir();
			
			if(dir){
				captureScreen(dir, numberOfImages, callback);
			}else{
				app.native.chooseDirectory(function(dir){
					if(dir.length){
						setCaptureDirectory(dir);
						captureScreen(dir, numberOfImages, callback);
					}
				});
			}
		}else{
			// no data
			app.dialog.error("No image data available, please make sure a device is connected before attempting to capture a screen.");
			callback();
		}
	}
	
	function setCaptureDirectory(dir){
		var obj = {dir: dir};
		app.storage.setItem(KEY, obj);
	}
		
	// public API
	return {capture: capture,
			setCaptureDirectory: setCaptureDirectory};
}());
