import $ from 'jquery'
import app from './app'

const COMMAND_APP_SWITCH = 1
const COMMAND_BROWSER = 2
const COMMAND_BACK = 3
const COMMAND_CALL = 4
const COMMAND_CAMERA = 5
const COMMAND_CONTACTS = 6
const COMMAND_EMAIL = 7
const COMMAND_HOME = 8
const COMMAND_MENU = 9
const COMMAND_PAGE_UP = 10
const COMMAND_PAGE_DOWN = 11
const COMMAND_POWER = 12
const COMMAND_SEARCH = 13
const COMMAND_ZOOM_IN = 14
const COMMAND_ZOOM_OUT = 15
const COMMAND_DISCONNECT = 16
const COMMAND_SUSPEND = 17
export const COMMAND_RESUME = 18
const COMMAND_UNLOCK = 19
const COMMAND_REBOOT = 20
const COMMAND_END = 21

export default app.commands = (function () {
  'use strict'

  const KEY = 'rcCommandButtonLayout'

  // private methods
  function restoreButtonLayout () {
    var buttons = app.storage.getItem(KEY)

    if (buttons) {
      // show / hide
      setButtonVisibility(buttons)

      // reorder
      setButtonOrder(buttons)
    }
  }

  function setButtonVisibility (buttons) {
    buttons.forEach(function ($this) {
      var id = ('#' + $this.id)
      var enabled = $this.enabled

      if (enabled) {
        $(id).show()
      } else {
        $(id).hide()
      }
    })
  }

  function setButtonOrder (buttons) {
    var $prev = null
    var $controlGroup = $('.footer').find('.controlgroup')

    buttons.forEach(function ($this) {
      var id = ('#' + $this.id)

      if ($prev) {
        // move after previous
        $(id).insertAfter($prev)
      } else {
        // move to top
        $(id).prependTo($controlGroup)
      }

      $prev = $(id)
    })
  }

  function onButton ($this, command) {
    // $this.disabled = true;

    send(command, function (err) {
      $this.disabled = false
    })
  }

  function legendHandler () {
    var $legend = $('.footer').find('fieldset').find('legend')
    var legendBackgroundColor = $legend.css('background-color')

    $legend.css({
      color: 'white',
      fontSize: 16,
      'margin-left': '150px'
    })

    /*	 This is commented temporarily, need to understand complete set of features available
		and accordingly release to the user.
	$legend.hover(function(e){
			if(e.type === "mouseenter"){
				$(this).css({
					cursor: "pointer",
					color: "black",
					background: "white"
				});
			}if(e.type === "mouseleave"){
				$(this).css({
					cursor: "default",
					color: "white",
					background: legendBackgroundColor
				});
			}
		});

		$legend.click(function(){
			app.dialog.commands();
		}); */
  }

  function buttonHandler () {
    var $button = $('.footer').find('button')

    $button.hover(function (e) {
      if (e.type === 'mouseenter') {
        $(this).css({ cursor: 'pointer' })
        $(this).addClass('ui-state-active')
      } if (e.type === 'mouseleave') {
        $(this).css({ cursor: 'default' })
        $(this).removeClass('ui-state-active')
      }
    })

    $('#unlock-button').click(function () {
      onButton(this, COMMAND_UNLOCK)
    })

    $('#disconnect-button').click(function () {
      onButton(this, COMMAND_DISCONNECT)
    })

    $('#zoom-in-button').click(function () {
      onButton(this, COMMAND_ZOOM_IN)
    })

    $('#zoom-out-button').click(function () {
      onButton(this, COMMAND_ZOOM_OUT)
    })

    $('#app-switch-button').click(function () {
      onButton(this, COMMAND_APP_SWITCH)
    })

    $('#search-button').click(function () {
      onButton(this, COMMAND_SEARCH)
    })

    $('#page-up-button').click(function () {
      onButton(this, COMMAND_PAGE_UP)
    })

    $('#page-down-button').click(function () {
      onButton(this, COMMAND_PAGE_DOWN)
    })

    $('#menu-button').click(function () {
      onButton(this, COMMAND_MENU)
    })

    $('#home-button').click(function () {
      onButton(this, COMMAND_HOME)
    })

    $('#back-button').click(function () {
      onButton(this, COMMAND_BACK)
    })

    $('#browser-button').click(function () {
      onButton(this, COMMAND_BROWSER)
    })

    $('#contacts-button').click(function () {
      onButton(this, COMMAND_CONTACTS)
    })

    $('#email-button').click(function () {
      onButton(this, COMMAND_EMAIL)
    })

    $('#camera-button').click(function () {
      onButton(this, COMMAND_CAMERA)
    })

    $('#call-button').click(function () {
      onButton(this, COMMAND_CALL)
    })

    $('#power-button').click(function () {
      onButton(this, COMMAND_POWER)
    })

    $('#reboot-button').click(function () {
      onButton(this, COMMAND_REBOOT)
})

    $('#screen-capture-button').click(function () {
      app.screen.capture(function () {
      })
    })

    if (app.debug) {
      $('#start-recording-button').click(function () {
        app.tcpServer.startRecording()
      })

      $('#stop-recording-button').click(function () {
        app.tcpServer.stopRecording()
      })

      $('#play-recording-button').click(function () {
        app.tcpServer.playbackRecording()
      })
    }
  }

  // public methods
  function initButtons () {
    var $footer = $('.footer')
    var buttons = defaultButtonLayout()
    var html =	'<fieldset>' +
						'<legend>Device Commands</legend>' +
						'<div class="controlgroup">'

    buttons.forEach(function ($this) {
      var id = $this.id
      var name = $this.name
      var enabled = $this.enabled ? 1 : 0

      if (enabled) {
        html +=		'<button class="ui-state-default" id="' + id + '">' + name + '</button>'
      } else {
        html +=		'<button class="ui-state-default" style="display:none" id="' + id + '">' + name + '</button>'
      }
    })
    html +=			'</div>' +
					'</fieldset>'

    $footer.html(html)

    legendHandler()
    buttonHandler()
    restoreButtonLayout()
  }

  function send (number, callback) {
    if (number && number > 0 && number < COMMAND_END) {
      if (app.debug && app.adb) {
        app.adbClient.command(number)
        callback()
      } else {
        var obj = { command: number }
        app.server.write(obj, callback)
      }
    }
  }

  function saveButtonLayout () {
    var buttons = []
    var $buttons = $('.footer').find('button')

    $buttons.each(function () {
      var $this = $(this)
      var obj = {
        id: $this.attr('id'),
        enabled: $this.is(':visible')
      }

      buttons.push(obj)
    })

    app.storage.setItem(KEY, buttons)
  }

  function defaultButtonLayout () {
    var buttons = []

    buttons.push({ enabled: 1, id: 'app-switch-button', name: 'App Switch' })
    buttons.push({ enabled: 1, id: 'back-button', name: 'Back' })
    buttons.push({ enabled: 0, id: 'browser-button', name: 'Browser' })
    buttons.push({ enabled: 0, id: 'call-button', name: 'Call' })
    buttons.push({ enabled: 0, id: 'camera-button', name: 'Camera' })
    buttons.push({ enabled: 0, id: 'contacts-button', name: 'Contacts' })
    buttons.push({ enabled: 0, id: 'disconnect-button', name: 'Disconnect' })
    buttons.push({ enabled: 0, id: 'email-button', name: 'Email' })
    buttons.push({ enabled: 1, id: 'home-button', name: 'Home' })
    buttons.push({ enabled: 0, id: 'menu-button', name: 'Menu' })
    buttons.push({ enabled: 1, id: 'page-up-button', name: 'Page Up' })
    buttons.push({ enabled: 1, id: 'page-down-button', name: 'Page Down' })
    buttons.push({ enabled: 1, id: 'power-button', name: 'Power' })
    buttons.push({ enabled: 0, id: 'reboot-button', name: 'Reboot' })
    buttons.push({ enabled: 0, id: 'screen-capture-button', name: 'Screen Capture' })
    buttons.push({ enabled: 1, id: 'search-button', name: 'Search' })
    buttons.push({ enabled: 1, id: 'unlock-button', name: 'Unlock' })
    buttons.push({ enabled: 0, id: 'zoom-in-button', name: 'Zoom +' })
    buttons.push({ enabled: 0, id: 'zoom-out-button', name: 'Zoom -' })

    if (app.debug) {
      buttons.push({ enabled: 0, id: 'start-recording-button', name: 'Start Recording' })
      buttons.push({ enabled: 0, id: 'stop-recording-button', name: 'Stop Recording' })
      buttons.push({ enabled: 0, id: 'play-recording-button', name: 'Play Recording' })
    }

    return buttons
  }

  // public API
  return {
    send: send,
    initButtons: initButtons,
    saveButtonLayout: saveButtonLayout,
    defaultButtonLayout: defaultButtonLayout
  }
}())

/*
https://www.pcworld.com/article/184656/android_keyboard_shortcuts.html
Hightlight Text
Cut Text
Copy Text
Copy text to clipboard

Delete an entire line
Move cursor to beginning of line
Move cursor to end of line
Move cursor to top of page
Move cursor to bottom of page

Go back a page
*Go forward a page
Refresh current page
Open bookmarks
Open social network sharing menu
View browsing history

*Open settings
Open browser settings

Gmail
Forward current message
Reply to current message
Reply-all to current message
Compose new e-mail
Open an e-mail
Jump to top of inbox
Jump to bottom of inbox

Android Keyboard Shortcuts: Apps
• Search + B: Open browser
• Search + C: Open contacts
• Search + E: Open e-mail
• Search + G: Open Gmail
• Search + I: Open calendar
• Search + M: Open maps
• Search + P: Open music
• Search + S: Open text messaging
• Search + Y: Open YouTube

*/
