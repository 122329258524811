import styled from 'styled-components/macro'
import { Button, Icon } from '@scuf/common'
import { DataTable } from '@scuf/datatable'

export const FolderGridContainer = styled.div`
    width: 79.8%; 
    padding: 0rem 1rem 1rem;
    display: flex;
    flex-direction: column; 
    overflow-y: auto;  
`
export const StyledHeaderWrapper = styled.div`
  padding: 2rem 0rem 0rem;
  display: flex;
  align-items: center;
`

export const StyledFolderName = styled.label`
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 800;
    color: #303030;
`
export const StyledDataTable = styled(DataTable)`
  min-height: 50vh;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  & .cell-data-wrap {
      font-size: .875rem;
      @media (max-width: 768px) {
        font-size: .8rem;
        padding: 0 0.25rem !important;
      }
  }
  & .p-selection-column{
    @media (max-width: 768px) {
      display: none;
    }
  }
  & .p-datatable-header .select-all-checkbox{
    @media (max-width: 768px) {
      display: none;
    }
  }
  & .p-datatable-row {
      height: 32px !important;
  }

  & .p-checkbox-box {
      max-width: 1rem !important;
      max-height: 1rem !important;
  }
 
  & .p-checkbox-icon {
      font-size:.5rem !important;
      top:-3px !important;
  }

  & .p-datatable-header {
      max-height: 3rem;
      background-color:#fff !important;
  }
  & .input-wrap {
      margin: .25rem 0 .25rem 0 ;
  }
  & .total-count {
      font-size: .875rem;
      font-weight:400;
  }
  & .p-datatable-scrollable-colgroup{
    display: none
  }

  & .ui.forge-datatable-wrap .p-datatable .p-datatable-thead > tr > th, .ui.forge-datatable-wrap .p-datatable .p-datatable-tfoot > tr > td{
    padding: 0.937rem 0.8rem 0.937rem 0.8rem !important;
  }

  tbody{
    max-height: 50vh;
    overflow-y: scroll;
    display: block;
  }

  thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed;/* even columns width , fix width of table too*/
  }

  & .wrap-text{
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }
`
export const ToggleProvisioingSwitch = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-left: 27.5rem;
  
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 19px;
  }

  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 20px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 30px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`
export const StyledLabel = styled.span`
    font-size: 1rem;  
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.5rem;
    color: #303030;
`
export const StyledButton = styled(Button)`
  width: 100% !important;
  margin-left: 0 !important;
  background-color: #FFF !important;
  border: none !important;  
  padding: 0 !important;
  text-transform: none !important; 
  color: #303030 !important;
  height: 100% !important;
  background: inherit !important;
  & .button-content:hover {
    
  }
  & .content-wrap{
      font-size: .875rem !important;
      font-weight: 400;
      line-height: 1rem !important;
      opacity: 1;
      transition: opacity 300ms ease-in;
  }
  &:before {
    background: rgba(34,36,38,0.1) !important;
  } 

  &.refresh-button{
    width: auto !important;
    padding: .5rem !important;
    margin-right: .2rem !important;
    margin-left: auto !important;
   /*  &:hover {
      background: #E0E0E0 !important;
    }*/
  }   
`
export const StyledIcon = styled(Icon)`
  padding-top: .35rem !important;
  font-size: .875rem !important;
  font-weight: 400 !important;

  &.refresh-icon{
    margin-right: .3rem;
    font-weight: 900 !important;
    padding-top: .225rem !important;
  }
`
export const StyledUploadProgressContainer = styled.div`
  margin-top: auto;
  display: flex;
  background: #FFF;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1rem;
  margin-bottom: .5rem;
  border: 1px solid;
  border-color: ${p => p.uploadingFailedStatus ? '#BE271C' : '#1274B7'};
`

/* export const StyledProgressCol1 = styled.div`
  columns: 2;
  column-rule: 1px solid #d0d0d0;
  gap: 1rem;
` */

export const StyledProgressCol1 = styled.div`
  display: flex;
  column-rule: 1px solid #d0d0d0;
  width: ${p => (p.percent === 100 ? '100%' : 'auto')};
`

export const StyledProgressCol2 = styled.div`
  width: 50%;
  display: flex;
  gap: 1rem;
`

/* & .w3-grey {
  color: #000!important;
  background-color: #1792e5 !important;
  height: 100%;
  max-width: 100%;
} */
export const StyledProgressBoldLabel = styled.div`
  font-weight: bold;
`
export const StyledIconCheck = styled(Icon)`
  color: #1274B7 !important;
  padding-top: .25rem !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  margin-right: .5rem;
`
export const StyledIconClose = styled(Icon)`
  order: 5;
  color: #303030 !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  padding-top: .25rem !important;
  margin-right: .5rem;
  margin-left: auto;
  cursor: pointer;
`

export const StyledIconWarning = styled(Icon)`
  color: #BE271C !important;
  padding-top: .25rem !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  margin-right: .5rem;
`

export const StyledProgressNamelabel = styled.div`
  
`
export const StyledFailedWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
`
export const StyledUploadFailedText = styled.div``

export const StyledDot = styled.div`
  height: 8px;
  width: 8px;
  background-color: #BE271C;
  border-radius: 50%;
  margin-right: 4px;
`

export const StyledProgressBarWrapper = styled.div`
  width: 100%;
  border: 1px solid #CCC !important;
  height: .75rem;
  align-self: center;
`
export const StyledUploadPercent = styled.div`
  color: #000!important;
  background-color: #1792E5 !important;
  height: 100%;
  width: 0%;
  max-width: 100%;
`
