import app from './app'

export default app.frame = (function () {
  

  const SIZEOF_BYTE = 1
  const SIZEOF_WORD = 2
  const SIZEOF_DWORD = 4

  const PROTOCOL_VERSION = 1

  const PORTRAIT = 0
  const SCREEN_WIDTH = 360
  const SCREEN_HEIGHT = 640

  const IMAGE_FRAME = 1
  const MOUSE_FRAME = 2
  const KEYPRESS_FRAME = 3
  const COMMAND_FRAME = 4
  const INFO_FRAME = 5
  const KEYBOARD_STRING_INPUT_FRAME = 6
  const SCROLL_FRAME = 7

  const KEY_FROM_KEYBOARD = 1
  const KEY_FROM_KEYPAD = 2

  const SIZEOF_SCROLL_HEADER = 10
  const SIZEOF_REMOTE_INPUT_HEADER = 6

  const SIZEOF_MOUSE_FRAME = ((SIZEOF_BYTE * 3) + (SIZEOF_WORD * 2))
  const SIZEOF_COMMAND_FRAME = (SIZEOF_BYTE * 2) + SIZEOF_WORD

  // private methods

  // from ArrayBuffer to Buffer
  function toBuffer (ab) {
    let buf = new Buffer(ab.byteLength)
    let view = new Uint8Array(ab)
    for (let i = 0; i < buf.length; ++i) {
      buf[i] = view[i]
    }
    return buf
  }

  // from Buffer to ArrayBuffer
  function toArrayBuffer (buf) {
    let ab = new ArrayBuffer(buf.length)
    let view = new Uint8Array(ab)
    for (let i = 0; i < buf.length; ++i) {
      view[i] = buf[i]
    }
    return ab
  }

  function countUtf8Bytes (s) {
    let b = 0; let i = 0; let c

    for (;c = s.charCodeAt(i++); b += c >> 11 ? 3 : c >> 7 ? 2 : 1);
    return b
  }

  // public methods
  function pushByte (byte, obj) {
    if (obj && obj.buf) {
      obj.buf[obj.index++] = byte
    }
  }

  function popByte (obj) {
    if (obj && obj.buf) {
      return obj.buf[obj.index++]
    }
    return null
  }

  function pushShort (word, obj) {
    if (obj && obj.buf) {
      app.byteorder.htons(word, obj)
    }
  }

  function popShort (obj) {
    if (obj && obj.buf) {
      return app.byteorder.ntohs(obj)
    }
    return null
  }

  function pushLong (dword, obj) {
    if (obj && obj.buf) {
      app.byteorder.htonl(dword, obj)
    }
  }

  function popLong (obj) {
    if (obj && obj.buf) {
      return app.byteorder.ntohl(obj)
    }
    return null
  }

  function mouse (e) {
    /* var buf = new Uint8Array(SIZEOF_MOUSE_FRAME),
			obj = {buf: buf, index: 0};
		console.log(e)
		pushByte(PROTOCOL_VERSION, obj);
		pushByte(MOUSE_FRAME, obj);
		pushByte(e.state, obj);
		pushShort(e.x, obj);
		pushShort(e.y, obj); */
    let buf = []
    buf.push(PROTOCOL_VERSION)
    buf.push(MOUSE_FRAME)
    buf.push(e.state)
    buf.push(parseInt(e.x))
    buf.push(parseInt(e.y))
    if (app.nodeWebkit) {
      // must return buffer, convert ArrayBuffer to Buffer
      return toBuffer(buf)
    } else {
      return buf
    }
    return null
  }

  function keyboard (key) {
    let bytes = sizeInBytes(key.code)
    let frameSize = SIZEOF_BYTE + SIZEOF_BYTE + SIZEOF_BYTE + bytes
    let buf = new Uint8Array(frameSize)
    let obj = { buf, index: 0 }

    pushByte(PROTOCOL_VERSION, obj)
    pushByte(KEYPRESS_FRAME, obj)
    /* if(key.keyboard){
		pushByte(KEY_FROM_KEYBOARD, obj);
		}else if(key.keypad){
			pushByte(KEY_FROM_KEYPAD, obj);
		} */
    pushByte(bytes, obj)

    if (bytes === SIZEOF_BYTE) {
      pushByte(key.code, obj)
    } else if (bytes === SIZEOF_WORD) {
      pushShort(key.code, obj)
    } else if (bytes === SIZEOF_DWORD) {
      pushLong(key.code, obj)
    }

    if (app.nodeWebkit) {
      // must return buffer, convert ArrayBuffer to Buffer
      return toBuffer(buf)
    } else {
      return buf
    }
  }

  function sizeInBytes (number) {
    if (number <= 255) {
      return SIZEOF_BYTE
    } else if (number <= 65535) {
      return SIZEOF_WORD
    } else {
      return SIZEOF_DWORD
    }
  }

  function command (number) {
    let buf = new Uint8Array(SIZEOF_COMMAND_FRAME)
    let obj = { buf, index: 0 }

    pushByte(PROTOCOL_VERSION, obj)
    pushByte(COMMAND_FRAME, obj)
    pushShort(number, obj)

    if (app.nodeWebkit) {
      // must return buffer, convert ArrayBuffer to Buffer
      return toBuffer(buf)
    } else {
      return buf
    }
  }

  function remoteInput (str) {
    let buf = new Uint8Array(SIZEOF_REMOTE_INPUT_HEADER + str.length)
    let obj = { buf, index: 0 }

    pushByte(PROTOCOL_VERSION, obj)
    pushByte(KEYBOARD_STRING_INPUT_FRAME, obj)
    pushLong(str.length, obj)

    for (let ii = 0; ii < str.length; ii++) {
      let ch = str.charCodeAt(ii)
      pushByte(ch, obj)
    }

    if (app.nodeWebkit) {
      // must return buffer, convert ArrayBuffer to Buffer
      return toBuffer(buf)
    } else {
      return buf
    }
  }

  function scroll (direction) {
    let buf = new Uint8Array(SIZEOF_SCROLL_HEADER)
    let obj = { buf, index: 0, dir: direction }

    pushByte(PROTOCOL_VERSION, obj)
    pushByte(SCROLL_FRAME, obj)

    if (direction === 'up') {
      pushShort(300, obj)
      pushShort(300, obj)
      pushShort(500, obj)
      pushShort(1000, obj)
    } else if (direction === 'down') {
      pushShort(500, obj)
      pushShort(1000, obj)
      pushShort(300, obj)
      pushShort(300, obj)
    } else if (direction === 'left') {
    } else if (direction === 'right') {
    }

    if (app.nodeWebkit) {
      // must return buffer, convert ArrayBuffer to Buffer
      return toBuffer(buf)
    } else {
      return buf
    }
  }

  function protocolVersion () {
    return PROTOCOL_VERSION
  }

  function isMouseEvent (frame) {
    if (frame && frame.type === MOUSE_FRAME) {
      return true
    }
    return false
  }

  function isKeyPressEvent (frame) {
    if (frame && frame.type === KEYPRESS_FRAME) {
      return true
    }
    return false
  }

  function isImage (type) {
    if (type === IMAGE_FRAME) {
      return true
    }
    return false
  }

  function isInfo (type) {
    if (type === INFO_FRAME) {
      return true
    }
    return false
  }

  function objectToBuffer (obj) {
    let buf

    if (obj) {
      if (obj.code && (obj.keyboard || obj.keypad)) {
        buf = keyboard(obj)
      } else if (obj.x && obj.y && obj.state) {
        buf = mouse(obj)
      } else if (obj.command) {
        buf = command(obj.command)
      } else if (obj.input) {
        buf = remoteInput(obj.input)
      }
    }

    return buf
  }

  // public API
  return {
    pushByte,
    pushShort,
    pushLong,
    popByte,
    popShort,
    popLong,
    scroll,
    isInfo,
    isImage,
    isMouseEvent,
    isKeyPressEvent,
    protocolVersion,
    objectToBuffer
  }
}())
