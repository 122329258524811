import React from 'react'
import { Icon } from '@scuf/common'

import {
  StyledErrorContainer,
  StyledItemContainer,
  OnboardTitle,
  OnboardSubtitle
} from './file-explorer.styles'

const UserNotification = ({ title, subTitle }) => {
  return (
    <StyledErrorContainer>
      <StyledItemContainer>
        <Icon root='common' name='badge-info' size='xlarge' />
        <OnboardTitle>{title}</OnboardTitle>
        <OnboardSubtitle>{subTitle}</OnboardSubtitle>
      </StyledItemContainer>
    </StyledErrorContainer>
  )
}

export default UserNotification
