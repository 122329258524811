import { pipe } from 'ramda'
import { connect } from 'react-redux'

import { FileExplorerActions } from '../../store'
import FolderGrid from './folder-grid'
import mapStateToProps from './folder-grid.selector'

const mapDispatchToPrps = dispatch => ({
  startUploading: (fileName) => dispatch(FileExplorerActions.startUploading(fileName)),
  uploadingFailed: () => dispatch(FileExplorerActions.uploadingFailed()),
  resetCurrentUploadingDetails: () => dispatch(FileExplorerActions.resetCurrentUploadingDetails()),
  insertUploadWaitingResponse: (fileName) => dispatch(FileExplorerActions.insertUploadWaitingResponse(fileName)),
  removeUploadWaitingResponse: (fileName) => dispatch(FileExplorerActions.removeUploadWaitingResponse(fileName)),
  startDownloading: (fileName) => dispatch(FileExplorerActions.startDownloading(fileName)),
  downloadingCompleted: () => dispatch(FileExplorerActions.downloadingCompleted()),
  downloadingFailed: () => dispatch(FileExplorerActions.downloadingFailed()),
  updateDownloadingPercentage: (downloadPercentage) => dispatch(FileExplorerActions.updateDownloadingPercentage(downloadPercentage)),
  resetCurrentDownloadingDetails: () => dispatch(FileExplorerActions.resetCurrentDownloadingDetails())
})

export default pipe(
  connect(mapStateToProps, mapDispatchToPrps)
)(FolderGrid)
