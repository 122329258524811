// import streamSaver from 'streamsaver'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

import { fixBinary } from 'Utils/file-helpers'
import { sendStartDownloadCommand } from 'Services/webrtc-connection'
import {
  logEvent,
  LOG_FE_FILEDOWNLOAD_COMPLETED,
  LOG_FE_FILEDOWNLOAD_FAILED
} from './log-helper'

let receivedSize = 0
let numberOfFiles = 0
let receiveBuffer = []
let receivedFileName = ''
let updateDownloadPercentageCb
let downloadingFailedCb
let downloadingCompletedCb

export function downloadFileSaver (data) {
  try {
    const downloadFileTotalSize = Number(data.size)
    const decodedData = fixBinary(atob(data.data))
    receiveBuffer.push(new Uint8Array(decodedData))
    receivedSize += decodedData.length
    updateDownloadPercentageCb(calculateDownloadPercent(receivedSize, downloadFileTotalSize))
    if (receivedSize === downloadFileTotalSize) {
      const received = new Blob(receiveBuffer)
      if (numberOfFiles > 1) {
        saveAs(received, data.fileName)
        logEvent(LOG_FE_FILEDOWNLOAD_COMPLETED, { fileName: data.fileName })
        receivedSize = 0
        receiveBuffer = []
        numberOfFiles = 0
      } else {
        const zip = new JSZip()
        zip.loadAsync(received)
          .then((loadedZip) => {
            receivedFileName = Object.keys(loadedZip.files)[0]
            if (!receivedFileName.endsWith('/')) {
              return zip.file(receivedFileName).async('nodebuffer')
            } else {
              return Promise.reject(receivedFileName)
            }
          })
          .then((contentBuffer) => {
            const receiveBufferZip = []
            receiveBufferZip.push(new Uint8Array(contentBuffer))

            const receivedBlob = new Blob(receiveBufferZip)
            saveAs(receivedBlob, receivedFileName)
            logEvent(LOG_FE_FILEDOWNLOAD_COMPLETED, { fileName: receivedFileName })
          }, (name) => {
            const fileName = `${name.slice(0, name.length - 1)}.zip`
            saveAs(received, fileName)
            logEvent(LOG_FE_FILEDOWNLOAD_COMPLETED, { fileName })
          })
          .finally(() => {
            receiveBuffer = []
            receivedSize = 0
            numberOfFiles = 0
            receivedFileName = ''
          })
          .catch(() => {
            logEvent(LOG_FE_FILEDOWNLOAD_FAILED)
            downloadingFailedCb()
          })
      }
    }
  } catch (e) {
    downloadingFailedCb()
  }
}

export function updateDownloadDetails (fileNames, path, { updateDownloadingPercentage, downloadingCompleted, downloadingFailed }) {
  numberOfFiles = Array.isArray(fileNames) ? fileNames.length : 1
  updateDownloadPercentageCb = updateDownloadingPercentage
  downloadingCompletedCb = downloadingCompleted
  downloadingFailedCb = downloadingFailed
  sendStartDownloadCommand(Array.isArray(fileNames) ? [...fileNames].toString() : fileNames, path)
}

function calculateDownloadPercent (receivedSize, downloadFileTotalSize) {
  if (receivedSize === downloadFileTotalSize) {
    // downloadingFailedCb()
    downloadingCompletedCb()
    return 100
  }
  return Math.round((receivedSize / downloadFileTotalSize) * 100)
}

/* export function downloadFileSaver (data, endOfDownloadingFile) {
  const downloadFileTotalSize = Number(data.size)
  if (!fileStream) {
    fileStream = streamSaver.createWriteStream(data.fileName)
    writer = fileStream.getWriter()
  }

  const decodedData = fixBinary(atob(data.data))
  decodedData && writer.write(new Uint8Array(decodedData))
  receivedSize += decodedData.length

  if (receivedSize === downloadFileTotalSize) {
    writer.close()
    endOfDownloadingFile()
    receivedSize = 0
    fileStream = null
  }
} */
