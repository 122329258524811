export const isMobile = () => window.screen.width < 720

export const isMobilexs = window.screen.width < 321

export const UPLOAD_FILE_MAX_SIZE_BYTE = 700000000

export const FILE_CHUNK_SIZE = 32768

export const DATACHANNEL_FILE_DOWNLOAD_COMMAND = 'FILE_DOWNLOAD'

export const DATACHANNEL_FILE_UPLOAD_COMMAND = 'FILE_UPLOAD'

export const DATACHANNEL_FILE_DELETE_COMMAND = 'FILE_DELETE'

export const DATACHANNEL_FILE_EXPLORER_TOGGLE = 'FILE_EXPLORER_TOGGLE'

export const DATACHANNEL_FILE_DATA_COMMAND = 'data'

export const DATACHANNEL_IMAGE_TYPE = 'image'

export const DATACHANNEL_FILE_STRUCTURE_TYPE = 'fileStructure'

export const DATACHANNEL_FILE_TYPE = 'file'

export const DATACHANNEL_PROVISIONING_MODE_ENABLED = 'PROVISIONING_MODE_ENABLED'

export const DATACHANNEL_PROVISIONING_MODE_DISABLED = 'PROVISIONING_MODE_DISABLED'

export const DATACHANNEL_PROVISIONING_STATUS = 'provisionStatus'

export const DEFAULT_ACTIVE_FOLDER_PATH = '/storage/emulated/0'

export const INTERNAL_STORAGE = '/storage/emulated/0'

export const IPSM_PATH = '/storage/IPSM'

export const SDCARD_PATH = '/storage'

export const PROVISIONING_SDCARD_FOLDER_PATH = '/storage/emulated/0/honeywell'

export const PROVISIONING_IPSM_FOLDER_PATH = '/storage/IPSM/honeywell'
