import React, { useState, useEffect, useCallback, useRef } from 'react'
import { toast } from 'react-toastify'

import { ErrorToast, WarningToast } from 'Themes/ScufStyledComponents'
import { uploadFile } from 'Utils/upload-helper'
import { updateDownloadDetails } from 'Utils/file-download-helper'
import { UPLOAD_FILE_MAX_SIZE_BYTE } from 'Utils/constants'
import UserNotification from '../user-notification'
import FolderTable from './folder-table'
import ProgressBar from '../ProgressBar'
import DeleteModal from '../DeleteModal'
import { ProgressLoader, LoaderContainer } from '../progress-loader'
import {
  sendDeleteFileCommand,
  sendProvisioningCommand,
  getFileExplorerStructure
} from 'Services/webrtc-connection'
import {
  FolderGridContainer,
  StyledHeaderWrapper,
  StyledFolderName,
  ToggleProvisioingSwitch,
  StyledLabel,
  StyledButton,
  StyledIcon
} from './folder-grid.styles'

const FolderGrid = ({
  activeFolderDetails,
  provisioningMode,
  uploadingInProgress,
  uploadingFailedStatus,
  uploadWaitingResponseDetails,
  downloadingInProgress,
  downloadingDetails,
  startUploading,
  resetCurrentUploadingDetails,
  insertUploadWaitingResponse,
  removeUploadWaitingResponse,
  startDownloading,
  downloadingCompleted,
  downloadingFailed,
  updateDownloadingPercentage,
  resetCurrentDownloadingDetails,
  deviceManufacturerInfo
}) => {
  const { name, path, childrenFiles } = activeFolderDetails
  const { downloadFileName, downloadInProgress, downloadFailedStatus, downloadPercentage } = downloadingDetails
  const [selectedChildrens, setSelectedChildrens] = useState([])
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [toggleProvisioning, setToggleProvisioning] = useState(provisioningMode)
  const [uploadFileName, setUploadFileName] = useState('')
  const [uploadPercentage, setUploadPercentage] = useState(-1)
  const [loading, setLoading] = useState(false)

  const uploadWaitingResponseRef = useRef()

  useEffect(() => {
    setSelectedChildrens([])
    setLoading(false)
  }, [activeFolderDetails])

  useEffect(() => {
    setToggleProvisioning(provisioningMode)
    setLoading(false)
  }, [provisioningMode])

  useEffect(() => {
    uploadWaitingResponseRef.current = uploadWaitingResponseDetails
  }, [uploadWaitingResponseDetails])

  const handleDownloadCallback = useCallback(
    () => {
      if (downloadingInProgress) {
        toast(<WarningToast message='Downloading file(s) is in progress, Please wait.' />)
      } else {
        const fileNames = selectedChildrens.map(file => file.name)
        updateDownloadDetails(fileNames, path, { updateDownloadingPercentage, downloadingCompleted, downloadingFailed })
        startDownloading(fileNames.join(', '))
      }
      setSelectedChildrens([])
    },
    [downloadingInProgress, selectedChildrens, path, updateDownloadingPercentage, downloadingCompleted, downloadingFailed, startDownloading]
  )

  const handleDeleteCallback = useCallback(
    () => {
      sendDeleteFileCommand(selectedChildrens, path)
      setSelectedChildrens([])
      setIsDeleteModalOpen(false)
    },
    [selectedChildrens, path]
  )

  const handleCancelCallback = useCallback(
    () => {
      setSelectedChildrens([])
      setIsDeleteModalOpen(false)
    }, [setSelectedChildrens, setIsDeleteModalOpen]
  )

  const handleProvisioningCallback = useCallback(() => {
    setLoading(true)
    setToggleProvisioning((preValue) => !preValue)
    sendProvisioningCommand(!toggleProvisioning)
  }, [toggleProvisioning])

  const handleRefreshCallback = useCallback(() => {
    getFileExplorerStructure()
    setLoading(true)
  }, [])

  const updateProgressPercentage = useCallback((percent) => {
    setUploadPercentage(percent)
  }, [setUploadPercentage])

  const endOfUploading = useCallback((fileName) => {
    insertUploadWaitingResponse(fileName)
    setTimeout(() => {
      const index = uploadWaitingResponseRef.current.findIndex((name) => name === fileName)
      if (index >= 0) {
        toast(<ErrorToast message={`Uploading ${fileName} file is failed.`} />)
        removeUploadWaitingResponse(fileName)
      }
    }, 60000)
  }, [insertUploadWaitingResponse, removeUploadWaitingResponse])

  const handleUploadCallBack = useCallback(
    (file) => {
      if (!file) return
      if (file.size > UPLOAD_FILE_MAX_SIZE_BYTE) {
        toast(<WarningToast message='File size should be less than 700MB.' />)
        return
      }
      startUploading(file.name)
      setUploadFileName(file.name)
      setUploadPercentage(0)
      uploadFile(file, path, updateProgressPercentage, endOfUploading)
    }, [startUploading, path, updateProgressPercentage, endOfUploading]
  )

  const handleUploadCloseProgressBar = useCallback(() => {
    setUploadPercentage(-1)
    resetCurrentUploadingDetails()
  }, [setUploadPercentage, resetCurrentUploadingDetails])

  const handleDownloadCloseProgressBar = useCallback(() => {
    resetCurrentDownloadingDetails()
  }, [resetCurrentDownloadingDetails])

  return (
    <FolderGridContainer>
      <LoaderContainer>
        <ProgressLoader overlayOpacity={0.5} loading={loading} />
      </LoaderContainer>
      <StyledHeaderWrapper>
        <StyledFolderName>
          {name}
        </StyledFolderName>
        { (deviceManufacturerInfo === null || deviceManufacturerInfo === 'Honeywell') && 
        <ToggleProvisioingSwitch>
          <label className='switch'>
            <input type='checkbox' onChange={handleProvisioningCallback} checked={toggleProvisioning} />
            <span className='slider round' />
          </label>
          <StyledLabel>Provisioning Mode</StyledLabel>
        </ToggleProvisioingSwitch>
        }
        <StyledButton className='refresh-button' data-command='1' onClick={handleRefreshCallback}>
          <StyledIcon className='refresh-icon' name='refresh' size='small' root='common' />
          <span className='content-wrap'>Refresh</span>
        </StyledButton>
      </StyledHeaderWrapper>
      {name.length > 0
        ? <>
          <FolderTable
            childrenFiles={childrenFiles}
            selectedChildrens={selectedChildrens}
            setSelectedChildrens={setSelectedChildrens}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            uploadingInProgress={uploadingInProgress}
            handleUploadCallBack={handleUploadCallBack}
            handleDownloadCallback={handleDownloadCallback}
          />
          {uploadPercentage >= 0
            ? <ProgressBar
              progressLabel='Upload Progress'
              failedLabel='Upload Failed'
              fileName={uploadFileName}
              percentage={uploadPercentage}
              inProgress={uploadingInProgress}
              failedStatus={uploadingFailedStatus}
              handleCloseProgressBar={handleUploadCloseProgressBar}
            /> : null}
          {downloadPercentage >= 0
            ? <ProgressBar
              progressLabel='Download Progress'
              failedLabel='Download Failed'
              fileName={downloadFileName}
              percentage={downloadPercentage}
              inProgress={downloadInProgress}
              failedStatus={downloadFailedStatus}
              handleCloseProgressBar={handleDownloadCloseProgressBar}
            /> : null}
          <DeleteModal
            openModal={isDeleteModalOpen}
            handleDelete={handleDeleteCallback}
            handleCancel={handleCancelCallback}
          />
        </>
        : <UserNotification title='Please select a folder.' />}
    </FolderGridContainer>
  )
}

export default React.memo(FolderGrid)
