const isValidJsonString = str => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  export {
      isValidJsonString
  }