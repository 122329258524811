import styled from 'styled-components/macro'
import { Modal } from '@scuf/common'

export const StyledModal = styled(Modal)`
  background: ${props => props.theme.background};
  & .content {
    overflow: unset !important;
    margin-bottom: 3rem !important;
    background: ${props => props.theme.background};
    & .content-top.heading {
      color: ${props => props.theme.h1color};
    }
  }
  & .actions {
    padding:1rem !important;
  }
  & label {
    color: ${props => props.theme.bodyfontcolor};
  }
  & .ui.button.primary.disabled{
    cursor: default !important;
  }
  & .steps.ui .link.item{
    height: 80px !important;
  }
  & .ui.steps .step .title{
    font-size: 1rem;
  }
  & .ui.ordered.steps .step:before{
    font-size: 1.75rem;
    margin-right: .5rem !important;
  }
  & .ui.steps .step{
    justify-content: flex-start !important;
  }
`
export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledContentBody = styled.div`
  display:flex;
  min-height: 50vh;
  max-height: 50vh;
  border: 1px solid #E0E0E0;
  padding: 1rem;
  overflow: auto;
`
